<div
  [ngClass]="{
    'animate__fadeInDownBig animate__fast': isScrollToTop === 'top',
    'animate__fadeOutUpBig animate__slow': isScrollToTop === 'down'
  }"
  class="
    fixed
    bg-white
    w-5/12
    z-10
    grid grid-cols-8
    gap-7
    xl:h-16
    place-items-center
    border-b border-light-gray
    px-7
    animate__animated
  "
>
  <ng-container *ngFor="let epoch of epochs; let i = index">
    <a
      [routerLink]="['/epoch']"
      [queryParams]="{order: epoch.order}"
      class="bg-no-repeat bg-cover bg-center h-12 w-12 rounded-full filter"
      [ngClass]="{ grayscale: epoch.order !== activeEpoch }"
      [ngStyle]="{
        'background-image': 'url(' + epoch.content.mini_image + ')'
      }"
    ></a>
  </ng-container>
</div>
