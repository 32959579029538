import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

interface IEpochContent {
  description: string;
  event_points: any;
  route: any;
  menu_name: string;
  mini_image: string;
  full_image: string;
  route_file: string;
}

export interface IEpoch {
  content: IEpochContent;
  events: Array<any>;
  name: string;
  order: number;
  persons: Array<any>;
}

export interface HttpEpochs {
  data: IEpoch[]
}

export interface IEpochDataService {
  getEpochsData(): Observable<any>,
}

@Injectable({
  providedIn: 'root',
})
export class EpochDataService implements IEpochDataService {
  public epochs: IEpoch[] = [];
  private domain = environment.apiUrl;
  private route = '/epochs';

  constructor(private http: HttpClient) {}

  public getEpochsData(): Observable<HttpEpochs> {
    return this.http.get<HttpEpochs>(`${this.domain}${this.route}`, {reportProgress: true});
  }

  public getEpochs(): Observable<HttpEvent<any> | HttpResponse<HttpEpochs>> {
    const request = new HttpRequest('GET',`${this.domain}${this.route}`,  {reportProgress: true});
    return this.http.request(request);
  }
}
