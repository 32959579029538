import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageContainerComponent } from './page-container/page-container.component';
import { DecorPageContainerComponent } from './decor-page-container/decor-page-container.component';

@NgModule({
  declarations: [PageContainerComponent, DecorPageContainerComponent],
  imports: [CommonModule],
  exports: [PageContainerComponent, DecorPageContainerComponent],
})
export class TemplatesModule {}
