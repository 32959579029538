import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'btn-fill-elastic',
  templateUrl: './btn-fill-elastic.component.html',
})
export class BtnFillElasticComponent implements OnInit {
  @Input() type = 'button';
  // tslint:disable-next-line: variable-name
  @Input() full_width = false;

  constructor() {}

  ngOnInit(): void {}
}
