<div class="grid grid-cols-1 mb-6 lg:mb-10">
  <div
    class="flex flex-row justify-center lg:justify-start items-center space-x-4 w-full"
  >
    <ng-container *ngIf="!heritageArchiveApiService.isWWOactive; else allOff">
      <btn-fill-elastic>Все</btn-fill-elastic>
    </ng-container>
    <ng-template #allOff>
      <btn-stroke-elastic
        (click)="heritageArchiveApiService.getHeritageArchiveData()"
        >Все</btn-stroke-elastic
      >
    </ng-template>

    <ng-container *ngIf="heritageArchiveApiService.isWWOactive; else wwoOff">
      <btn-fill-elastic>Участники I Мировой Войны</btn-fill-elastic>
    </ng-container>

    <ng-template #wwoOff>
      <btn-stroke-elastic (click)="wwoHandler()"
        >Участники I Мировой Войны</btn-stroke-elastic
      >
    </ng-template>
  </div>
</div>

<!-- Для десктопных экранов -->
<form
  [formGroup]="haFilterService.filterForm"
  (ngSubmit)="heritageArchiveApiService.filterOnSubmit()"
  class="hidden lg:grid grid-cols-1 gap-8"
>
  <div class="grid grid-cols-4 gap-3 xl:gap-5">
    <!-- Поиск -->
    <app-input
      [form]="haFilterService.filterForm"
      fieldName="name"
      fieldLabel="Поиск"
    >
      <input
        id="name"
        (focus)="formsService.setActiveFiealStyle($event)"
        (blur)="formsService.setActiveFiealStyle($event)"
        (keyup)="changeOnHandler()"
        formControlName="name"
        name="name"
        type="text"
        autocomplete="off"
        class="standart-input tracking-normal transition duration-300 ease-in-out"
        placeholder="Иванов Иван Петров"
      />
    </app-input>

    <!-- Звание  -->
    <div class="relative">
      <app-input
        [form]="haFilterService.filterForm"
        fieldName="rank"
        fieldLabel="Звание"
      >
        <input
          id="rank"
          (focus)="formsService.setActiveFiealStyle($event)"
          (blur)="formsService.setActiveFiealStyle($event)"
          (click)="dropDownOpen = !dropDownOpen"
          (keyup)="changeOnHandler()"
          formControlName="rank"
          name="rank"
          type="text"
          [readOnly]="true"
          autocomplete="off"
          class="standart-input tracking-normal transition duration-300 ease-in-out cursor-pointer"
          placeholder="Офицер"
        />
        <div class="flex flex-row items-center">
          <img
            [ngClass]="{
              'transform rotate-180 transition duration-300 ease-in-out': dropDownOpen
            }"
            class="select-none"
            src="../../../../../assets/icons/arrow_drop_down.svg"
          />
        </div>
      </app-input>

      <!-- Выпадающий список -->
      <ng-container *ngIf="dropDownOpen">
        <div
          (blur)="dropDownOpen = false"
          class="absolute top-20 w-full bg-white border-gray-100 shadow-md z-10 rounded-md"
        >
          <p
            (click)="haFilterService.setRank('Не выбрано...')"
            (click)="dropDownOpen = false"
            class="text-base text-black py-3.5 px-4 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
          >
            Не выбрано...
          </p>
          <p
            (click)="haFilterService.setRank('Офицер')"
            (click)="dropDownOpen = false"
            class="text-base text-black py-3.5 px-4 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
          >
            Офицеры
          </p>
          <p
            (click)="haFilterService.setRank('Нижний чин')"
            (click)="dropDownOpen = false"
            class="text-base text-black py-3.5 px-4 hover:bg-gray-100 transition duration-300 ease-in-out cursor-pointer"
          >
            Нижний чин
          </p>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Кнопки -->
  <div class="grid grid-cols-1 place-items-center">
    <div class="flex flex-row items-center space-x-4">
      <btn-fill-fixed type="submit">Поиск</btn-fill-fixed>
      <btn-stroke-fixed (click)="haFilterService.resetFilterForm()"
        >Сбросить</btn-stroke-fixed
      >
    </div>
  </div>
</form>

<!-- Для моб устройств -->
<form
  [formGroup]="haFilterService.filterForm"
  (ngSubmit)="heritageArchiveApiService.filterOnSubmit()"
  class="grid grid-cols-1 gap-1 lg:hidden"
>
  <!-- Поиск -->
  <app-input
    [form]="haFilterService.filterForm"
    fieldName="name"
    fieldLabel="Поиск"
  >
    <input
      id="name"
      (focus)="formsService.setActiveFiealStyle($event)"
      (blur)="formsService.setActiveFiealStyle($event)"
      formControlName="name"
      name="name"
      type="text"
      autocomplete="off"
      class="standart-input tracking-normal transition duration-300 ease-in-out"
      placeholder="Иванов Иван Петров"
    />
  </app-input>

  <app-input
    [form]="haFilterService.filterForm"
    fieldName="rank"
    fieldLabel="Звание"
  >
    <input
      id="rank"
      (focus)="formsService.setActiveFiealStyle($event)"
      (blur)="formsService.setActiveFiealStyle($event)"
      formControlName="rank"
      name="rank"
      type="text"
      [readOnly]="true"
      autocomplete="off"
      class="standart-input tracking-normal transition duration-300 ease-in-out"
      placeholder="Офицер"
    />
    <div class="flex flex-row items-center">
      <img
        class="select-none"
        src="../../../../../assets/icons/arrow_drop_down.svg"
      />
    </div>
  </app-input>

  <!-- Кнопки -->
  <div class="grid grid-cols-1 place-items-center mt-4">
    <div class="flex flex-row items-center space-x-4">
      <btn-fill-fixed type="submit">Поиск</btn-fill-fixed>
      <btn-stroke-fixed (click)="haFilterService.resetFilterForm()"
        >Сбросить</btn-stroke-fixed
      >
    </div>
  </div>
</form>
