<ng-container *ngIf="preloaderService.isPreloaderShow">
  <preloader></preloader>
</ng-container>

<app-page-container>
  <div class="grid grid-cols-1 gap-12">
    <!-- Заголовок страницы -->
    <app-center-page-head
      [pageTitle]="routerService.pageTitle"
      pageDescription="9-го гренадерского Сибирского Его Императорского Величества Великого
  князя Николая Николаевича Старшего полка"
    >
    </app-center-page-head>

    <!-- Фильтры -->
    <app-archive-filter></app-archive-filter>

    <!-- Контент вывода элементов архива -->
    <ng-container
      *ngIf="heritageArchiveApiService.persons.length > 0; else empty"
    >
      <div
        class="
          grid grid-cols-1
          md:grid-cols-2
          lg:grid-cols-3
          xl:grid-cols-4
          gap-8
          lg:gap-x-5 lg:gap-y-6
        "
      >
        <ng-container
          *ngFor="let item of heritageArchiveApiService.persons; index as i"
        >
          <app-archive-card
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            data-aos-offset="50"
            [person]="item"
          ></app-archive-card
        ></ng-container>
      </div>

      <ng-container *ngIf="heritageArchiveApiService.pageCounter > 1">
        <app-archive-pagination
          [links]="heritageArchiveApiService.links"
        ></app-archive-pagination>
      </ng-container>
    </ng-container>

    <ng-template #empty>
      <div class="flex flex-row justify-center w-full">
        <h6>Ничего не найдено</h6>
      </div>
    </ng-template>
  </div>
</app-page-container>

<!-- Футер -->
<app-footer></app-footer>
