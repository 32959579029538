<div class="audio-wrapper">
  <vm-player 
    icons="material"  
    [currentTime]="currentTime"
    (vmCurrentTimeChange)="onTimeUpdate($event)"
  > 
    <vm-audio>
      <source data-src="{{audio}}" type="audio/mp3" />
    </vm-audio>
    <vm-ui>
      <vm-icon-library
      name="my-library"
      [resolver]="customResolver"
    ></vm-icon-library>
      <vm-controls>
        <vm-control
          label="Назад"
          (click)="seekBackward()"
        >
        <vm-icon name="rewind" library="material"></vm-icon>
      </vm-control>
      <vm-playback-control 
        hideTooltip="{{true}}" 
        icons="my-library"
        style="
          --vm-control-scale: 1.6;
        "
      >
      </vm-playback-control>
      <vm-control
        label="Вперёд"
        (click)="seekForward()"
      >
        <vm-icon name="fast-forward" library="material"></vm-icon>
        <!-- <vm-tooltip>Вперёд</vm-tooltip> -->
      </vm-control>
      <vm-time-progress separator="/"></vm-time-progress>
      <vm-scrubber-control></vm-scrubber-control>
      <vm-volume-control hideTooltip="{{true}}"></vm-volume-control>
      </vm-controls>
    </vm-ui>
  </vm-player>
</div>
