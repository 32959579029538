import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../ui/ui.module';
import { ComponentsModule } from '../views/components/components.module';
import { ExternalRoutes } from '../routes/external.routes';

@NgModule({
  declarations: [],
  imports: [CommonModule, UiModule, ComponentsModule],
  exports: [UiModule, ComponentsModule],
  providers: [ExternalRoutes],
})
export class SharedModule {}
