import { Injectable } from '@angular/core';
import { APIservice } from 'src/app/core/api/api.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs'

interface IEpochContent {
  description: string;
  event_points: any;
  route: any;
  menu_name: string;
  mini_image: string;
  full_image: string;
  route_file: string;
}

export interface IEpoch {
  content: IEpochContent;
  events: Array<any>;
  name: string;
  order: number;
  persons: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class EpochApiService {
  public epochs: IEpoch[] = [];

  constructor(private api: APIservice, private http: HttpClient) {}

  public async getEpochsData(): Promise<void> {
    this.api.GET('/epochs').subscribe((res) => {
      this.epochs = res.data;
    });
  }

  public getCurrentEpoch(order: string): any {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.epochs.length; i++) {
      if (String(this.epochs[i].order) === order) {
        return this.epochs[i];
      }
    }
  }

  public getEpochRoute(filePath: string): Promise<any> {
    return this.http.get(filePath).toPromise()   
  }

  public getAllEpochRoutes(order?: number): Promise<Object[]> {
    const filtered = order ? this.epochs.filter((epoch) => epoch.order !== order): this.epochs;
    const requests = filtered.map((epoch) => this.http.get(epoch.content.route_file));
    return forkJoin(requests).toPromise();
  }
}
