import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeritageArchivePageComponent } from './heritage-archive-page/heritage-archive-page.component';
import { TemplatesModule } from '../../templates/templates.module';
import { ArchiveFilterComponent } from './components/archive-filter/archive-filter.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ArchiveCardComponent } from './components/archive-card/archive-card.component';
import { HeritageArchiveFilterService } from './services/heritage-archive-filter.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HeritageArchiveApiService } from './services/heritage-archive.service';
import { HttpClientModule } from '@angular/common/http';
import { ArchivePaginationComponent } from './components/archive-pagination/archive-pagination.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeritageArchivePageComponent,
    ArchiveFilterComponent,
    ArchiveCardComponent,
    ArchivePaginationComponent,
  ],
  imports: [
    CommonModule,
    TemplatesModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [HeritageArchiveFilterService, HeritageArchiveApiService],
})
export class HeritageArchiveModule {}
