<div
  #wrapper
  class="
    grid
    fixed
    grid-cols-1
    xl:grid-cols-12
    w-full
    z-40
    hidden
    "
  >
  <div class="xl:col-span-7 w-full h-screen flex flex-col content-center justify-center bg-overlay"> 
    <div class="video-wrapper">
      <button (click)="close()" class="close-video">
        <span class="video-control-icon video-control-close"></span>
      </button>
      <vm-player #player playsInline [aspectRatio]="aspectRatio">
        <vm-video>
          <source [src]="video" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
        </vm-video>
        <vm-ui>
          <vm-scrim></vm-scrim>
          <vm-click-to-play useOnMobile="true"></vm-click-to-play>
          <vm-controls  *ngIf="caption" pin="top" justify="center">
            <vm-control
              hide-tooltip
              hide-when-paused
              style="
                --vm-control-focus-bg: transparent;
                --vm-control-color: #000;
                --vm-control-focus-color: #000;
                --vm-control-scale: 1;
              "
            >
              <div class="video-caption-wrapper">
                <h6>{{caption}}</h6>
              </div>
            </vm-control>
          </vm-controls>
          <vm-controls active-duration="1500">
            <vm-scrubber-control></vm-scrubber-control>
            <vm-volume-control hide-tooltip></vm-volume-control>
            <vm-fullscreen-control hide-tooltip></vm-fullscreen-control>
          </vm-controls>
          <vm-controls pin="center" justify="center" fullWidth="false" active-duration="1500">
            <vm-playback-control
              hide-tooltip
              hide-when-paused
              style="
                --vm-control-scale: 2.1;
                --vm-control-bg: #000;
                --vm-control-border-radius: 50%;
                --vm-control-focus-bg: #013;
              "
            ></vm-playback-control>
          </vm-controls>
        </vm-ui>
      </vm-player>
    </div>
  </div>
</div>