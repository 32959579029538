import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpochPageComponent } from './epoch-page/epoch-page.component';
import { MapComponent } from './components/map/map.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';
import { ImperatorsPanelComponent } from './components/imperators-panel/imperators-panel.component';
import { ImperatorsPanelMobileComponent } from './components/imperators-panel-mobile/imperators-panel-mobile.component';
import { CombatFormModalComponent } from './components/combat-form-modal/combat-form-modal.component';
import { PersonsComponent } from './components/persons/persons.component';
import { ContentComponent } from './components/content/content.component';
import { RomanizePipe } from 'src/app/pipes/romanize.pipe';
import { LegendComponent } from './components/legend/legend.component';
import { CombatFormComponent } from './components/combat-form/combat-form.component';
import { RouterModule } from '@angular/router';
import { EscapeHtmlPipe } from 'src/app/pipes/escapeHtml.pipe';
import { ImagePanzoomComponent } from './components/image-panzoom/image-panzoom.component';
import { AudioControlComponent } from './components/audio-control/audio-control.component';
import { VimeModule } from '@vime/angular';
import { VideoControlComponent } from './components/video-control/video-control.component';

@NgModule({
  declarations: [
    EpochPageComponent,
    MapComponent,
    ImperatorsPanelComponent,
    ImperatorsPanelMobileComponent,
    CombatFormModalComponent,
    PersonsComponent,
    ContentComponent,

    RomanizePipe,
    EscapeHtmlPipe,

    LegendComponent,

    CombatFormComponent,

    ImagePanzoomComponent,

    AudioControlComponent,

    VideoControlComponent,
  ],
  imports: [CommonModule, TemplatesModule, SharedModule, RouterModule, VimeModule],
  providers: [],
})
export class EpochModule {}
