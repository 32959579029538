import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPageComponent } from './card-page/card-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';
import { CardApiService } from './service/card-api.service';

@NgModule({
  declarations: [CardPageComponent],
  imports: [CommonModule, TemplatesModule, SharedModule],
  providers: [CardApiService],
})
export class HeritageArchiveCardModule {}
