<div
  class="relative flex flex-col items-center bg-white h-full w-full my-6 mx-6"
>
  <!-- Заголовок -->
  <div class="flex flex-col items-center space-y-3.5 w-full">
    <h3 class="ibm-plex-serif font-bold text-black text-center text-3xl">
      Содержание
    </h3>
    <p class="text-sm open-sans text-black">
      Эпоха <span class="font-bold">Петра I Алексеевича</span>
    </p>
  </div>

  <!-- Оглавление -->
  <div class="flex flex-col space-y-5 w-full mt-20">
    <!-- Введения -->
    <div class="flex flex-row space-x-3.5 items-center w-full h-12">
      <div class="h-full w-1 bg-primary"></div>
      <div class="flex flex-col space-y-1 w-full">
        <p class="open-sans text-sm text-brown font-bold uppercase">Введение</p>
        <!-- <p class="open-sans text-sm text-black">
          Фактическая дата образования полка
        </p> -->
      </div>
    </div>

    <!-- Персоны -->
    <div class="flex flex-row space-x-3.5 items-center w-full h-12">
      <div class="h-full w-1 bg-primary"></div>
      <div class="flex flex-col space-y-1 w-full">
        <p class="open-sans text-sm text-brown font-bold uppercase">Персоны</p>
        <!-- <p class="open-sans text-sm text-black">
              Фактическая дата образования полка
            </p> -->
      </div>
    </div>
  </div>
</div>
