import { Injectable } from '@angular/core';
import { APIservice } from 'src/app/core/api/api.service';
import { IPerson } from '../../heritage-archive/classes/filter.classes';

@Injectable()
export class CardApiService {
  public person: IPerson;
  public isLoading = true;

  public activePhotoLink = { id: 0, src: '' };

  constructor(private api: APIservice) {
    // Инициализирую пустой объект песоны
    this.person = {
      content: {
        biography: '',
        facts: '',
        images: [''],
      },

      date_from: '',
      date_to: '',
      full_name: '',
      id: 0,
      rank_big: '',
      rank_little: '',
      world_war_one: '0',
    };
  }

  /**
   * Метод для получения персональной информации о персоне
   */
  public getPersonInfo(personID: number): void {
    // tslint:disable-next-line: deprecation
    this.api.GET('/persons?filter[id]=' + personID).subscribe((res) => {
      this.person = res.data[0];

      this.activePhotoLink = { id: 0, src: this.person.content.images[0] };

      // Отмечаю что загрузка выполнена
      this.isLoading = false;
    });
  }

  public changePhoto(i: number): void {
    this.activePhotoLink = { id: i, src: this.person.content.images[i] };

    console.log(this.activePhotoLink.src);
  }
}
