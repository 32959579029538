import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-combat-form',
  templateUrl: './combat-form.component.html',
})
export class CombatFormComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
