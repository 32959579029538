import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
})
export class MapComponent implements OnInit {
  isFullScreen = false;
  paperMapStatus = false;

  constructor(public contentService: ContentService) {
    document.addEventListener('DOMContentLoaded', () => {
      const elems = document.querySelectorAll('.tooltipped');
      M.Tooltip.init(elems, {});
    });
  }

  ngOnInit(): void {}

  /**
   * Развернуть на весь экран
   */
  public toFullScreen(): void {
    document.documentElement.requestFullscreen();
    this.isFullScreen = true;
  }

  /**
   * Вернуть окно в нормальное состояние
   */
  public toNormalScreen(): void {
    document.exitFullscreen();
    this.isFullScreen = false;
  }

  public changeMap(): void {
    console.log('change');
  }
}
