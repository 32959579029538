import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoIntroService {
  private url = '/assets/video.mp4'
  constructor(private http: HttpClient) {}

  public getVideo(): Observable<HttpEvent<any>> {
    const request = new HttpRequest('GET', this.url,  {
      reportProgress: true,
      responseType: 'blob',
    });
    return this.http.request(request);
  }
}