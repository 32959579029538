<app-page-container>
  <div class="grid grid-cols-1">
    <!-- Заголовок страницы -->
    <app-center-page-head
      [pageTitle]="routerService.pageTitle"
      pageDescription="9-го гренадерского Сибирского Его Императорского Величества Великого
  князя Николая Николаевича Старшего полка"
    >
    </app-center-page-head>

    <!-- Кто знает -->
    <div class="grid grid-cols-1 gap-y-8 mt-8">
      <div class="flex flex-row justify-center w-full">
        <p class="text-xl open-sans text-black font-bold">Кто о нас знает?</p>
      </div>
      <div class="bg-gray-200 h-96 w-full"></div>
    </div>

    <!-- Каталог партнеров -->
    <div class="grid grid-cols-1 gap-y-8 mt-16">
      <div class="flex flex-row justify-center w-full">
        <p class="text-xl open-sans text-black font-bold">
          Наши информационные партнеры
        </p>
      </div>
      <div class="grid grid-cols-12 gap-6">
        <ng-container *ngFor="let item of [1, 3, 4, 6, 6, 6, 6, 6, 6, 6, 6, 6]">
          <a href="#" class="flex flex-col justify-center space-y-3">
            <div class="h-20 w-20 bg-gray-300 rounded-full"></div>
            <p class="text-base text-gray-800 font-medium">Какое-то название</p>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</app-page-container>

<!-- Футер -->
<app-footer></app-footer>
