import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeAnimation } from './animation/router.animation';
import * as Aos from 'aos';

import { Store, select } from '@ngrx/store';

import { selectAllEpochs } from './store/selectors/epoch.selectors';
import { IState } from './store';
import { LoadEpochs } from './store/actions/epoch.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routeAnimation],
})
export class AppComponent implements OnInit {
  title = 'history';
  epochs$ = this._store.pipe(select(selectAllEpochs));

  constructor(private _store: Store<IState>) {
    this._store.dispatch(new LoadEpochs());
    Aos.init();
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  public getRouteAnimationState(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }
}
