import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, HostListener  } from '@angular/core';
import { Player } from '@vime/angular';

@Component({
  selector: 'app-video-control',
  templateUrl: './video-control.component.html',
  styleUrls: ['./video-control.component.css'],
})
export class VideoControlComponent implements OnInit {
  @ViewChild('player') player!: Player;
  @ViewChild('wrapper', { read: ElementRef }) wrapper!: ElementRef;
 
  public video = '';
  public caption = '';
  public aspectRatio = '16:9';

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(ev: Event) {
    if (this.wrapper.nativeElement && !this.wrapper.nativeElement.classList.contains('hidden')) {
      this.aspectRatio = this.aspectRatio === '16:9' ? '16:8' : '16:9';
      this.changeDetection.detectChanges();
    }
  }

  constructor(private changeDetection: ChangeDetectorRef) {
    this.changeDetection.detach();
    this.videoRequestListener = this.videoRequestListener.bind(this);
  }

  ngOnInit(): void {
    document.addEventListener('requestVideo', this.videoRequestListener);
  }

  ngOnDestroy() {
    document.removeEventListener('requestVideo', this.videoRequestListener);
  }

  public close() {
    this.player.pause();
    this.video = '';
    this.caption = '';
    this.wrapper.nativeElement.classList.add('hidden');
    this.changeDetection.detectChanges();
  }

  private videoRequestListener(ev: Event) {
    const elem = ev.target as HTMLButtonElement;
    if (elem.dataset.video && typeof elem.dataset.video === 'string' && elem.dataset.video.endsWith('.mp4')) {
      this.video = elem.dataset.video;
      if(elem.dataset.caption) { 
        this.caption = elem.dataset.caption;
      }
      this.changeDetection.detectChanges();
      this.wrapper.nativeElement.classList.remove('hidden');
    }
  }

}
