import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { PreloaderService } from 'src/app/core/preloader/preloader.service';
import { RouterService } from 'src/app/core/router-navigations/router.service';;
import { HeritageArchiveApiService } from '../services/heritage-archive.service';

@Component({
  selector: 'app-heritage-archive-page',
  templateUrl: './heritage-archive-page.component.html',
})
export class HeritageArchivePageComponent implements OnInit {
  constructor(
    public preloaderService: PreloaderService,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute,
    public heritageArchiveApiService: HeritageArchiveApiService
  ) {
    this.routerService.setPageTitle(this.activatedRoute);

    // Запрашиваю данные по архиву наследия
    this.heritageArchiveApiService.getHeritageArchiveData();
  }

  ngOnInit(): void {}
}
