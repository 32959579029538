import { EpochActions, EpochsActionsUnion, IEpoch } from "../actions/epoch.actions";

interface IEpochsState {
  epochs: IEpoch[],
  isLoading: boolean,
  loadIsRequired: boolean,
  error: Error | null,
  selectedEpoch: IEpoch | null,
  progress: number,
  chunk: number
};

const initialState: IEpochsState = {
  epochs: [],
  progress: 0,
  isLoading: true,
  loadIsRequired: false,
  selectedEpoch: null,
  error: null,
  chunk: 0,
};

function epochsReducer( state: IEpochsState = initialState, action: EpochsActionsUnion):IEpochsState {
  switch(action.type) {
    case EpochActions.LoadEpochs:
      return {
        ...state,
        isLoading: true,
        loadIsRequired: false,
      };
    case EpochActions.LoadEpochsProgress:
      return {
        ...state,
        progress: action.payload.progress,
        chunk: action.payload.chunk || state.chunk,
      }
    case EpochActions.LoadEpochsSuccess:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        epochs: action.payload.epochs,
      };
    case EpochActions.LoadEpochsError:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        error: action.payload.error || null,
      };
    case EpochActions.LoadEpochsAsync:
    default:
      return state;
  }
}

export { epochsReducer, IEpochsState };
