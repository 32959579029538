import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnFillFixedComponent } from './btn-fill-fixed/btn-fill-fixed.component';
import { BtnStrokeFixedComponent } from './btn-stroke-fixed/btn-stroke-fixed.component';
import { BtnFillElasticComponent } from './btn-fill-elastic/btn-fill-elastic.component';
import { BtnStrokeElasticComponent } from './btn-stroke-elastic/btn-stroke-elastic.component';
import { LabelLightBrownComponent } from './label-light-brown/label-light-brown.component';
import { BtnFillRoundComponent } from './btn-fill-round/btn-fill-round.component';

@NgModule({
  declarations: [
    BtnFillFixedComponent,
    BtnStrokeFixedComponent,
    BtnFillElasticComponent,
    BtnStrokeElasticComponent,
    LabelLightBrownComponent,
    BtnFillRoundComponent,
  ],
  imports: [CommonModule],
  exports: [
    BtnFillFixedComponent,
    BtnStrokeFixedComponent,
    BtnFillElasticComponent,
    BtnStrokeElasticComponent,
    LabelLightBrownComponent,
    BtnFillRoundComponent,
  ],
})
export class UiModule {}
