import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { IState } from '../index';
import { EpochMapService } from "src/app/core/services/epochs-map.service";
import { LoadMapRoutes, LoadMapRoutesSuccess, LoadMapRoutesError, EMapRoutesActions, IRoutes, LoadMapRoutesAsync } from "../actions/map-routes.actions";


@Injectable()
export class MapEffects {
  getRoutes$ = createEffect(() => {
    return this._actions$.pipe(
      ofType<LoadMapRoutes>(EMapRoutesActions.LoadMapRoutes),
      switchMap(( action ) => {
        return this._mapService.getAllEpochRoutes(action.args).pipe(
          map((res) => {
            console.log('маршруты загружены') 
            return new LoadMapRoutesSuccess({ routes: res})
          }),
          catchError((error) => {
            console.log('При загрухке маршрутов произошла ошибка', error)
            return of(new LoadMapRoutesError({ error: new Error('Ошибка загрузки маршрутов') }))
          })
        )
      })
    )
  })

  constructor(
    private _mapService: EpochMapService,
    private _actions$: Actions,
    private _store: Store,
  ) {}
}