<!-- Контент выезжающего меню -->
<div id="slide-out" class="sidenav py-6 px-8 2xl:px-10 overflow-y-hidden">
  <!-- Logo -->
  <div
    (click)="navigationService.navigateTo('')"
    class="
      flex flex-row
      justify-center
      mb-10
      2xl:mb-14
      sidenav-close
      cursor-pointer
    "
  >
    <img
      class="select-none h-32"
      src="../../../../../assets/img/logo_white.png"
    />
  </div>

  <!-- Content -->
  <ul class="grid grid-cols-1 gap-6 collapsible">
    <!-- Эпохи -->
    <li class="active">
      <div class="collapsible-header flex flex-row items-center space-x-2.5">
        <img
          class="select-none h-4"
          src="../../../../../assets/icons/crown.svg"
        />
        <div class="flex flex-row items-center space-x-1">
          <p class="open-sans text-white font-semibold text-sm uppercase">
            Эпохи
          </p>
          <img
            class="select-none h-4"
            src="../../../../../assets/icons/arrow_drop_down_white.svg"
          />
        </div>
      </div>
      <!-- Эпохи  -->
      <div class="collapsible-body">
        <div *ngIf="!isLoading" class="flex flex-col space-y-2 ml-6 pt-2">
          <ng-container
            *ngFor="let epoch of epochs"
          >
            <a
              [routerLink]="['epoch']"
              [queryParams]="{order: epoch.order}"
              class="
                sidenav-close
                open-sans
                text-white
                font-normal
                text-sm
                hover:opacity-60
                transition
                duration-300
                ease-in-out
                cursor-pointer
              "
            >
              {{ epoch?.content?.menu_name }}
            </a>
          </ng-container>

          <!-- <a
            href="epoch"
            class="sidenav-close open-sans text-white font-normal text-sm hover:opacity-60 transition duration-300 ease-in-out cursor-pointer"
          >
            Пётр I Алексеевич
          </a> -->
        </div>
      </div>
    </li>

    <!-- О полку -->
    <li class="active">
      <div class="collapsible-header flex flex-row items-center space-x-2.5">
        <img
          class="select-none h-4"
          src="../../../../../assets/icons/polk.svg"
        />
        <div class="flex flex-row items-center space-x-1">
          <p class="open-sans text-white font-semibold text-sm uppercase">
            О полку
          </p>
          <img
            class="select-none h-4"
            src="../../../../../assets/icons/arrow_drop_down_white.svg"
          />
        </div>
      </div>
      <!-- Эпохи Content -->
      <div class="collapsible-body">
        <div class="flex flex-col space-y-2 ml-6 pt-2">
          <a
            (click)="navigationService.navigateTo(externalRoutes.foundation)"
            class="
              sidenav-close
              open-sans
              text-white
              font-normal
              text-sm
              hover:opacity-60
              transition
              duration-300
              ease-in-out
              cursor-pointer
            "
          >
            Рождение полка
          </a>
          <a
            (click)="
              navigationService.navigateTo(externalRoutes.military_glory)
            "
            class="
              sidenav-close
              open-sans
              text-white
              font-normal
              text-sm
              hover:opacity-60
              transition
              duration-300
              ease-in-out
              cursor-pointer
            "
          >
            Боевая слава
          </a>
        </div>
      </div>
    </li>

    <!-- Архив наследия -->
    <a
      (click)="
        navigationService.navigateTo(externalRoutes.heritage_archive)
      "
      class="
        sidenav-close
        flex flex-row
        items-center
        space-x-2.5
        cursor-pointer
      "
    >
      <img
        class="select-none h-4"
        src="../../../../../assets/icons/arhiv.svg"
      />
      <div class="flex flex-row items-center space-x-1">
        <p class="open-sans text-white font-semibold text-sm uppercase">
          Архив наследия
        </p>
      </div>
    </a>

    <!-- О книге -->
    <div
      (click)="navigationService.navigateTo(externalRoutes.about)"
      class="
        sidenav-close
        flex flex-row
        items-center
        space-x-2.5
        cursor-pointer
        hover:opacity-70
        transition
        duration-300
        ease-in-out
      "
    >
      <img
        class="select-none h-4"
        src="../../../../../assets/icons/book-opened.svg"
      />
      <div class="flex flex-row items-center space-x-1">
        <p class="open-sans text-white font-semibold text-sm uppercase">
          О книге
        </p>
      </div>
    </div>

    <!-- Контакты -->
    <div
      (click)="navigationService.navigateTo(externalRoutes.contact)"
      class="
        sidenav-close
        flex flex-row
        items-center
        space-x-2.5
        cursor-pointer
        hover:opacity-70
        transition
        duration-300
        ease-in-out
      "
    >
      <img
        class="select-none h-4"
        src="../../../../../assets/icons/contact.svg"
      />
      <div class="flex flex-row items-center space-x-1">
        <p class="open-sans text-white font-semibold text-sm uppercase">
          Контакты
        </p>
      </div>
    </div>

    <!-- Партнеры -->
    <!-- <div
      (click)="navigationService.navigateTo(externalRoutes.partners)"
      class="
        sidenav-close
        flex flex-row
        items-center
        space-x-2.5
        cursor-pointer
        pb-12
        hover:opacity-70
        transition
        duration-300
        ease-in-out
      "
    >
      <img
        class="select-none h-4"
        src="../../../../../assets/icons/partner.svg"
      />
      <div class="flex flex-row items-center space-x-1">
        <p class="open-sans text-white font-semibold text-sm uppercase">
          Партнеры
        </p>
      </div>
    </div> -->
  </ul>
</div>

<!-- Кнопка меню -->
<div
  data-target="slide-out"
  class="
    sidenav-trigger
    fixed
    w-16
    h-16
    2xl:w-20 2xl:h-20
    z-30
    top-0
    flex flex-col
    items-center
    justify-center
    rounded-br-3xl
    cursor-pointer
    bg-black
    opacity-50
    hover:opacity-80
    transition
    duration-300
    ease-in-out
  "
>
  <img
    class="select-none h-7 2xl:h-8"
    src="../../../../../assets/icons/menu.svg"
  />
</div>
