<div class="pt-24 xl:pt-56 pb-5 xl:pb-20 2xl:pb-32">
  <div
    class="flex flex-col justify-center items-center space-y-5 h-full w-full"
  >
    <h1
      class="open-sans font-bold text-black text-9xl animate__animated animate__bounce select-none"
    >
      404
    </h1>
    <h3 class="open-sans font-bold text-black text-4xl text-center">
      Старница не найдена...
    </h3>
    <p
      class="open-sans font-normal text-gray text-base text-center w-full xl:w-2/6"
    >
      Вы находитесь здесь потому, что запрашиваемая страница не существует или
      была перемещена по другому адресу.
    </p>
    <btn-fill-fixed (click)="navigationService.navigateTo('')"
      >Главную</btn-fill-fixed
    >
  </div>
</div>

<!-- Футер -->
<app-footer></app-footer>
