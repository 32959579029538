import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFilterFormModel } from '../classes/filter.classes';

@Injectable()
export class HeritageArchiveFilterService {
  public filterForm: FormGroup;

  public dateFrom = '';
  public dateTo = '';

  // Опциональные настройки для календаря
  // tslint:disable-next-line: variable-name
  public datepicker_options_from = {
    format: 'dd.mm.yyyy',
    autoClose: true,
    firstDay: 1,
    changeMonth: true,
    changeYear: true,
    defaultDate: new Date(1800, 1, 1),
    yearRange: [1800, 1920],
    onSelect: (date: any) => {
      this.dateFrom = new Date(date).toISOString();
    },
    i18n: {
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],

      monthsShort: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],

      weekdays: [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ],

      weekdaysShort: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      weekdaysAbbrev: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],

      cancel: 'Закрыть',
      clear: 'Очистить',
      done: 'Выбрать',
    },
  };

  // tslint:disable-next-line: variable-name
  public datepicker_options_to = {
    format: 'dd.mm.yyyy',
    autoClose: true,
    firstDay: 1,
    changeMonth: true,
    changeYear: true,
    defaultDate: new Date(1820, 1, 1),
    yearRange: [1800, 1920],
    onSelect: (date: any) => {
      this.dateTo = new Date(date).toISOString();
    },
    i18n: {
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],

      monthsShort: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],

      weekdays: [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ],

      weekdaysShort: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      weekdaysAbbrev: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],

      cancel: 'Закрыть',
      clear: 'Очистить',
      done: 'Выбрать',
    },
  };

  constructor(private fb: FormBuilder) {
    // Модель формы фильрации
    this.filterForm = this.createForm({
      name: ['', []],
      date_from: ['Выберите дату...', []],
      date_to: ['Выберите дату...', []],
      rank: ['Не выбрано...', []],
    });
  }

  /**
   * Метод для получения значения
   * формы через интерфейс формы
   */
  public get formValue(): IFilterFormModel {
    return this.filterForm.value as IFilterFormModel;
  }

  /**
   * Метод создающий формц фильтрации
   * @param model модель формы
   * @returns форму фильтрации
   */
  private createForm(model: IFilterFormModel): FormGroup {
    return this.fb.group(model);
  }

  /**
   * Установить выбранный ранг в форму фильтра
   * @param rankToSet выбранный ранг
   */
  public setRank(rankToSet: string): void {
    this.filterForm.setValue({
      name: this.formValue.name,
      date_from: this.formValue.date_from,
      date_to: this.formValue.date_to,
      rank: rankToSet,
    });
  }

  /**
   * Установить выбранную дату
   * @param rankToSet выбранный ранг
   */
  public setDateFrom(dateToSet: string): void {
    this.filterForm.setValue({
      name: this.formValue.name,
      date_from: dateToSet,
      date_to: this.formValue.date_to,
      rank: this.formValue.rank,
    });
  }

  /**
   * Установить выбранную дату
   * @param rankToSet выбранный ранг
   */
  public setDateTo(dateToSet: string): void {
    this.filterForm.setValue({
      name: this.formValue.name,
      date_from: this.formValue.date_from,
      date_to: dateToSet,
      rank: this.formValue.rank,
    });
  }

  /**
   * Метод для очистки формы фильтрации
   */
  public resetFilterForm(): void {
    this.dateFrom = '';
    this.dateTo = '';

    this.filterForm.setValue({
      name: '',
      date_from: 'Выберите дату...',
      date_to: 'Выберите дату...',
      rank: 'Не выбрано...',
    });
  }
}
