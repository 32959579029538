import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/core/router-navigations/router.service';
import { IEpoch } from 'src/app/store/actions/epoch.actions';
import { EpochApiService } from '../../services/epoch-api.service';

@Component({
  selector: 'app-imperators-panel',
  templateUrl: './imperators-panel.component.html',
})
export class ImperatorsPanelComponent implements OnInit {
  public isScrollToTop: 'top' | 'down' = 'top';
  @Input()
  public activeEpoch = 0;
  @Input()
  public epochs: IEpoch[] = [];

  constructor(
    // public epochApiService: EpochApiService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute
  ) {
    // this.route.queryParams.subscribe((params) => {
    //   this.activeEpoch = Number(params.order);
    // });
  }

  ngOnInit(): void {
    let lastScrollTop = 0;

    window.addEventListener(
      'scroll',
      () => {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        // Если сколл вниз
        if (lastScrollTop > 0 && st > lastScrollTop) {
          this.isScrollToTop = 'down';
        }
        // Если скролит вверх
        else {
          if (lastScrollTop > 0) {
            this.isScrollToTop = 'top';
          }
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
  }
}
