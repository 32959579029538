<div class="relative rounded-md border border-light-gray">
  <!-- Лейбы -->

  <div class="absolute flex flex-row items-center w-full mt-2.5 ml-3">
    <app-label-light-brown>{{ person.rank_little }}</app-label-light-brown>
  </div>

  <!-- Фото -->
  <div class="h-72 w-full">
    <!-- Если фото нет -->
    <ng-container *ngIf="person.content.images.length === 0; else photo">
      <div
        class="flex flex-col space-y-9 items-center place-items-center h-full w-full bg-light select-none"
      >
        <img class="mt-8" src="../../../../../assets/img/logo_gray.svg" />
        <p
          class="open-sans text-gray font-semibold text-sm leading-snug text-center"
        >
          Нет фото
        </p>
      </div>
    </ng-container>

    <ng-template #photo>
      <div
        class="h-full w-full bg-no-repeat bg-cover bg-center"
        [ngStyle]="{
          'background-image': 'url(' + person.content.images[0] + ')'
        }"
      ></div>
    </ng-template>
  </div>

  <!-- Контент -->
  <div class="grid grid-cols-1 place-items-center gap-y-1 w-full py-5 h-32">
    <p
      class="open-sans font-bold text-lg leading-snug text-black text-center px-10"
    >
      {{ person.full_name }}
    </p>
    <span
      class="open-sans font-normal text-sm leading-snug text-center mt-1 px-12"
      >{{ person.rank_big }}</span
    >
  </div>

  <!-- Блок с цифрами -->
  <div class="grid grid-cols-3 gap-6 pt-2 pb-6 px-20 lg:px-12">
    <!-- Фотографии -->
    <div class="flex flex-row justify-start w-full">
      <div class="grid grid-cols-1 gap-y-2 place-items-center">
        <img src="../../../../../assets/icons/image.svg" />
        <p class="open-sans font-bold text-sm text-black text-center">
          <ng-container *ngIf="person.content.images.length > 0; else imgEmpty">
            {{ person.content.images.length }}
          </ng-container>
          <ng-template #imgEmpty>—</ng-template>
        </p>
      </div>
    </div>

    <!-- Биография -->
    <div class="flex flex-row justify-center w-full">
      <div class="grid grid-cols-1 gap-y-2 place-items-center">
        <img src="../../../../../assets/icons/information.svg" />
        <p class="open-sans font-bold text-sm text-black text-center">
          <ng-container
            *ngIf="
              person.content.biography !== null &&
                person.content.biography !== '<p><br></p>';
              else bEmpty
            "
          >
            Есть
          </ng-container>
          <ng-template #bEmpty>—</ng-template>
        </p>
      </div>
    </div>

    <!-- Факты -->
    <div class="flex flex-row justify-end w-full">
      <div class="grid grid-cols-1 gap-y-2 place-items-center">
        <img src="../../../../../assets/icons/attach-file.svg" />
        <p class="open-sans font-bold text-sm text-black text-center">
          <ng-container
            *ngIf="
              person.content.facts !== null &&
                person.content.facts !== '<p><br></p>';
              else fEmpty
            "
          >
            Есть
          </ng-container>
          <ng-template #fEmpty>—</ng-template>
        </p>
      </div>
    </div>
  </div>

  <div class="w-full px-12 mt-1.5 mb-5">
    <btn-fill-elastic [full_width]="true" (click)="goToPerson(person.id)">
      Открыть</btn-fill-elastic
    >
  </div>
</div>
