<div class="flex flex-row w-full items-start">
  <!-- Картинка -->
  <div
    class="fixed img-container hidden lg:block h-full animate__animated animate__fadeInLeft animate__fast"
  >
    <img
      class="h-screen w-full select-none"
      src="../../../../../assets/img/military_glory_img.png"
    />

    <div
      class="absolute right-0 bottom-0 bg-black bg-opacity-70 py-4 px-6 mb-14"
    >
      <div class="flex flex-col space-y-0.5 mt-2">
        <p class="open-sans text-sm text-light-gray opacity-90">
          Георгиевский крест из навершия полкового Георгиевского знамени 9-го 
          гренадерского Сибирского полка (ВСМЗ)
        </p>
      </div>
      <div class="flex flex-col space-y-0.5 mt-2">

      </div>
    </div>
  </div>

  <!-- Контент -->
  <div
    class="absolute right-0 text-container mt-12 md:mt-14 lg:mt-9 pb-16 animate__animated animate__fadeInRight animate__fast"
  >
    <!-- Заголовок -->
    <app-head [pageTitle]="routerService.pageTitle"> </app-head>

    <!-- Текст -->
    <div class="mx-12 md:mx-28 lg:mx-24 2xl:mx-32 mt-10 lg:mt-12 2xl:mt-14">
      <p class="ibm-plex-serif text-black text-base select-none">
        19 ноября 1700 года полк принял свое боевое крещение в Северной войне
        под стенами крепости Нарва в сражении с войсками Карла XII. Тогда
        молодая русская армия была разбита хорошо организованной и опытной
        шведской армией. В сражении пропал без вести командир полка. Но уже
        через год, в бою под мызой Эрестфер полк одерживает свою первую победу.
        В боях и сражениях начального этапа войны полк принимал самое активное
        участие. Среди них: бой при Гумельсгофе, взятие замка Мариенбург, штурм
        Ямбурга и Копорья, разгром флотилии шведов при реке Эмбах, штурм Дерпта
        и Нарвы. <br /><br />
        В 1708 году полк был назван Сибирским пехотным полком. И в этом же году
        последовали бои при Добром, Мезине, Ромнах. И, конечно, сражение при
        Полтаве. Далее — взятие Риги и Прутский поход, участие в морских
        галерных походах и боях на «шведской стороне», взятие Гельсингфорса,
        городов Або, Тавастгуса, Кристиенстада, сражение при реке Пелкиной, бой
        при реке Лаппола, набег на Аландские острова и т.д. <br /><br />
        Позднее полку пришлось участвовать в войне «за польское наследство», в
        чудо- вищных по своим потерям «крымских походах» Русско-турецкой войны
        1736–1739 го- дов под командой фельдмаршала Миниха. В 1741–1743 годах в
        составе Финляндского корпуса полк принимал участие в войне со Швецией, а
        в период с 1756 по 1762 год Сибирский полк — в Семилетней войне. Дрался
        под Гросс-Егерсдорфом, при Пальциге и Кунерсдорфе. В 1768 году полк
        принимал участие в войне с польскими конфедерата- ми. В Русско-турецкой
        войне 1768–1774 годов полк дрался под Журжей, Ликореште, Туртукаем.
        <br /><br />
        В Русско-турецкой войне 1806–1812 годов полк участвовал в боях за
        Бухарест, в сра- жениях при Турбате, Обилешти, Расевате, в двух
        сражениях при Шумле, штурме Журжи, в бою при реке Янтра, крепости Рущук.
        <br /><br />
        Полк покрыл себя заслуженной славой во время Отечественной войны 1812
        года. В сражении при селе Бородино 24–26 августа 1812 года полк в
        составе 3-й бригады 2-й гренадерской дивизии под командой шефа полка
        полковника Д.А. Левина потерял 17 офицеров и 478 нижних чинов. Также
        полк участвовал в сражениях при Малоярославце, Лютцене, Бауцене,
        Дрездене, Арси-сюр-Обе, Париже. <br /><br />
        Во время польского мятежа 1830–1831 годов полк сражался при городе
        Остроленке, штурмовал пригород Варшавы Прагу. <br /><br />
        Полк в составе Гренадерского корпуса, участвовал в Русско-турецкой войне
        1877– 1878 годов. Под Плевной, когда турецкая армия Османа-паши
        обрушилась всей сво- ей мощью на русские позиции, первый ее удар приняли
        на себя сибирские гренадеры.
      </p>
    </div>

    <app-bottom-navigation
      [prevLink]="externalRoutes.foundation"
      prevTitle="Рождение полка"
    >
    </app-bottom-navigation>
  </div>
</div>
