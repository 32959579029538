<form
  [formGroup]="lettersService.letterForm"
  (ngSubmit)="lettersService.letterFormOnSubmit()"
  class="
    grid grid-cols-1
    md:grid-cols-2
    gap-6
    md:gap-8
    lg:gap-4
    xl:gap-3
    mb-10
    md:mb-16
  "
>
  <!-- Почта -->
  <div
    class="
      relative
      col-span-2
      md:col-span-1
      flex flex-col
      space-y-2.5
      w-full
      pb-5
    "
  >
    <label for="email" class="open-sans font-semibold text-black text-sm"
      >Почта</label
    >
    <div
      [ngClass]="{
        'border-light-gray': !lettersService.letterForm.get('email')?.touched,
        'border-blue':
          activeFieldAction === 'focus' && activeFieldID === 'email',
        'border-red-500':
          lettersService.letterForm.get('email')?.invalid &&
          lettersService.letterForm.get('email')?.touched,
        'border-green-600':
          lettersService.letterForm.get('email')?.valid &&
          lettersService.letterForm.get('email')?.touched
      }"
      class="
        border
        bg-white
        rounded-md
        w-full
        px-4
        transition
        duration-300
        ease-in-out
        z-10
      "
    >
      <input
        id="email"
        (focus)="setActiveFiealStyle($event)"
        (blur)="setActiveFiealStyle($event)"
        formControlName="email"
        name="email"
        type="email"
        autocomplete="off"
        class="standart-input tracking-normal"
        placeholder="ivan@mail.ru*"
      />
    </div>

    <div class="absolute bottom-0 select-none">
      <!-- Если человек не заполнил поле -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('email')?.invalid &&
          lettersService.letterForm.get('email')?.touched
        "
      >
        <p
          class="
            open-sans
            text-xs text-red-600
            animate__animated animate__slideInDown animate__faster
          "
        >
          Это обязательное поле
        </p>
      </ng-container>

      <!-- Если все верно заполнил -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('email')?.valid &&
          lettersService.letterForm.get('email')?.touched
        "
      >
        <p
          class="
            open-sans
            text-xs text-green
            animate__animated animate__slideInDown animate__faster
          "
        >
          Поле заполненно верно
        </p>
      </ng-container>
    </div>
  </div>

  <!-- Телефон -->
  <div
    class="
      relative
      col-span-2
      md:col-span-1
      flex flex-col
      space-y-2.5
      w-full
      pb-5
    "
  >
    <label for="phone" class="open-sans font-semibold text-black text-sm"
      >Телефон</label
    >
    <div
      [ngClass]="{
        'border-light-gray': !lettersService.letterForm.get('phone')?.touched,
        'border-blue':
          activeFieldAction === 'focus' && activeFieldID === 'phone',
        'border-red-500':
          lettersService.letterForm.get('phone')?.invalid &&
          lettersService.letterForm.get('phone')?.touched,
        'border-green-600':
          lettersService.letterForm.get('phone')?.valid &&
          lettersService.letterForm.get('phone')?.touched &&
          lettersService.formValue.phone !== ''
      }"
      class="
        border
        bg-white
        border-light-gray
        rounded-md
        w-full
        px-4
        transition
        duration-300
        ease-in-out
        z-10
      "
    >
      <input
        id="phone"
        (focus)="setActiveFiealStyle($event)"
        (blur)="setActiveFiealStyle($event)"
        formControlName="phone"
        name="phone"
        type="text"
        autocomplete="off"
        class="standart-input tracking-normal"
        placeholder="+7 929 829 06 89"
      />
    </div>

    <div class="absolute bottom-0">
      <!-- Если все верно заполнил -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('phone')?.valid &&
          lettersService.letterForm.get('phone')?.touched &&
          lettersService.formValue.phone !== ''
        "
      >
        <p
          class="
            open-sans
            text-xs text-green
            animate__animated animate__slideInDown animate__faster
          "
        >
          Поле заполненно верно
        </p>
      </ng-container>
    </div>
  </div>

  <!-- Имя -->
  <div class="relative col-span-2 flex flex-col space-y-2.5 w-full pb-5">
    <label for="full_name" class="open-sans font-semibold text-black text-sm"
      >Имя</label
    >
    <div
      [ngClass]="{
        'border-light-gray':
          !lettersService.letterForm.get('full_name')?.touched,
        'border-blue':
          activeFieldAction === 'focus' && activeFieldID === 'full_name',
        'border-red-500':
          lettersService.letterForm.get('full_name')?.invalid &&
          lettersService.letterForm.get('full_name')?.touched,
        'border-green-600':
          lettersService.letterForm.get('full_name')?.valid &&
          lettersService.letterForm.get('full_name')?.touched
      }"
      class="
        border
        bg-white
        rounded-md
        w-full
        px-4
        transition
        duration-300
        ease-in-out
        z-10
      "
    >
      <input
        id="full_name"
        (focus)="setActiveFiealStyle($event)"
        (blur)="setActiveFiealStyle($event)"
        formControlName="full_name"
        name="full_name"
        type="text"
        autocomplete="off"
        class="standart-input tracking-normal"
        placeholder="Иван Иванов*"
      />
    </div>

    <div class="absolute bottom-0 select-none">
      <!-- Если человек не заполнил поле -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('full_name')?.invalid &&
          lettersService.letterForm.get('full_name')?.touched
        "
      >
        <p
          class="
            open-sans
            text-xs text-red-600
            animate__animated animate__slideInDown animate__faster
          "
        >
          Это обязательное поле
        </p>
      </ng-container>

      <!-- Если все верно заполнил -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('full_name')?.valid &&
          lettersService.letterForm.get('full_name')?.touched
        "
      >
        <p
          class="
            open-sans
            text-xs text-green
            animate__animated animate__slideInDown animate__faster
          "
        >
          Поле заполненно верно
        </p>
      </ng-container>
    </div>
  </div>

  <!-- Комментарий -->
  <div class="relative col-span-2 flex flex-col space-y-2.5 w-full pb-5">
    <label for="comment" class="open-sans font-semibold text-black text-sm"
      >Комментарий</label
    >
    <div
      [ngClass]="{
        'border-light-gray': !lettersService.letterForm.get('comment')?.touched,
        'border-blue':
          activeFieldAction === 'focus' && activeFieldID === 'comment',
        'border-red-500':
          lettersService.letterForm.get('comment')?.invalid &&
          lettersService.letterForm.get('comment')?.touched,
        'border-green-600':
          lettersService.letterForm.get('comment')?.valid &&
          lettersService.letterForm.get('comment')?.touched
      }"
      class="
        border
        bg-white
        border-light-gray
        rounded-md
        w-full
        h-36
        transition
        duration-300
        ease-in-out
        z-10
      "
    >
      <textarea
        id="comment"
        (focus)="setActiveFiealStyle($event)"
        (blur)="setActiveFiealStyle($event)"
        formControlName="comment"
        name="comment"
        type="text"
        autocomplete="off"
        class="standart-input tracking-normal h-full py-3.5 px-4 resize-none"
        placeholder="Ваш текст... *"
      ></textarea>
    </div>

    <div class="absolute bottom-0 select-none">
      <!-- Если человек не заполнил поле -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('comment')?.invalid &&
          lettersService.letterForm.get('comment')?.touched
        "
      >
        <p
          class="
            open-sans
            text-xs text-red-600
            animate__animated animate__slideInDown animate__faster
          "
        >
          Это обязательное поле
        </p>
      </ng-container>

      <!-- Если все верно заполнил -->
      <ng-container
        *ngIf="
          lettersService.letterForm.get('comment')?.valid &&
          lettersService.letterForm.get('comment')?.touched
        "
      >
        <p
          class="
            open-sans
            text-xs text-green
            animate__animated animate__slideInDown animate__faster
          "
        >
          Поле заполненно верно
        </p>
      </ng-container>
    </div>
  </div>

  <div class="col-span-2 flex flex-row justify-center">
    <div class="flex flex-col items-center space-y-2.5 w-full">
      <btn-fill-fixed type="submit">Отправить</btn-fill-fixed>
      <p
        class="
          open-sans
          font-normal
          text-gray text-xs text-center
          leading-tight
          md:w-1/2
          lg:w-9/12
        "
      >
        Нажимая кнопку «Далее» вы подтверждаете своё согласие на
        <a href="#" class="text-blue">обработку персональных данных</a>.
      </p>
    </div>
  </div>
</form>
