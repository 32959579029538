import { Component, OnInit } from '@angular/core';
import { HeritageArchiveFilterService } from '../../services/heritage-archive-filter.service';
import * as M from 'materialize-css';
import { FormsService } from 'src/app/core/forms/forms.service';
import { HeritageArchiveApiService } from '../../services/heritage-archive.service';

@Component({
  selector: 'app-archive-filter',
  templateUrl: './archive-filter.component.html',
})
export class ArchiveFilterComponent implements OnInit {
  public arrowOpen = false;
  public dropDownOpen = false;

  constructor(
    public haFilterService: HeritageArchiveFilterService,
    public formsService: FormsService,
    public heritageArchiveApiService: HeritageArchiveApiService
  ) {

    // Разворачивающий список для моб устройств
    document.addEventListener('DOMContentLoaded', () => {
      const elems = document.querySelectorAll('.collapsible');
      const instance = M.Collapsible.init(elems, {
        accordion: false,
      });
    });
  }

  ngOnInit(): void {}

  /**
   * Обработка переключени на участников перо мировой
   */
  public wwoHandler(): void {
    this.heritageArchiveApiService.isWWOactive = true;
    this.heritageArchiveApiService.filterOnSubmit(true);
  }

  /**
   * Запрос на каждый ввод символа
   */
  public changeOnHandler(): void {
    if (
      this.haFilterService.filterForm.value.name.length > 2 ||
      this.haFilterService.filterForm.value.name.length === 0
    ) {
      this.heritageArchiveApiService.filterOnSubmit();
    }
  }
}
