import { ActionReducerMap } from '@ngrx/store';
import * as Epochs from './reducers/epoch.reducers';
import * as MapRoutes from './reducers/map-routes.reducers';


export interface IState {
  epochs: Epochs.IEpochsState,
  mapRoutes: MapRoutes.IMapRoutesState,
}

export const reducers: ActionReducerMap<IState, any> = {
  epochs: Epochs.epochsReducer,
  mapRoutes: MapRoutes.mapRoutesReducer,
}
