import { Component, OnInit, Input } from '@angular/core';

 const route_colors = {
  1: "#958079",
  2: "#AB843B",
  3: "#AFA93C",
  4: "#76AB3B",
  5: "#39A788",
  6: "#3893A3",
  7: "#3770A0",
  8: "#5838A3",
} as const;

type ColorKeys = keyof typeof route_colors;

function isColor(value: any): value is ColorKeys {
  return Number(value) in route_colors;
}

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html'
})
export class LegendComponent implements OnInit {
  @Input() order:ColorKeys | String = 1;
  public color = route_colors[isColor(this.order) ? this.order : 1];
  public src = isColor(this.order) ? `../../../../../assets/icons/battle_${this.order}.png` : "../../../../../assets/icons/battle.svg";

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.color = route_colors[isColor(this.order) ? this.order : 1];
    this.src = isColor(this.order) ? `../../../../../assets/icons/battle_${this.order}.png` : "../../../../../assets/icons/battle.svg";
  }

}
