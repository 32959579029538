import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class APIservice {
  public APIdomain = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /**
   * POST request
   * @param url `string` -> api url
   * @param body `object` -> body of request
   */
  public POST(url: string, body: object, headers?: any): Observable<any> {
    return this.http.post(this.APIdomain + url, body, { headers });
  }

  /**
   * GET request
   * @param url `string` -> api url
   */
  public GET(url: string, headers?: any): Observable<any> {
    if (url === '/auth/refresh-token' || url === '/auth/logout') {
      return this.http.get(url, { headers });
    }
    return this.http.get(this.APIdomain + url, { headers });
  }

  /**
   * PUT request
   * @param url `string` -> api url
   */
  public PUT(url: string, data: object, headers?: any): Observable<any> {
    return this.http.put(this.APIdomain + url, data, { headers });
  }

  /**
   * DELETE request
   * @param url `string` -> api url
   */
  public DELETE(url: string, headers?: any): Observable<any> {
    return this.http.delete(this.APIdomain + url, { headers });
  }

  /**
   * Функция создающая заголовок
   * @param token `string` -> access_token
   */
  public createAuthHeader(token: string): HttpHeaders {
    // Инициализирую Authorization: Bearer заголовок
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    // Задаю Authorization: Bearer заголовок
    headers = headers.set('Authorization', 'Bearer ' + token);

    return headers;
  }

  public createCSRFHeader(token: string): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    headers = headers.set('CSRF', token);

    return headers;
  }
}
