<ng-container *ngIf="!cardApiService.isLoading; else loading">
  <app-page-container>
    <!-- Декоративные элементы страницы -->
    <!-- <div class="hidden lg:block absolute top-0 right-0">
      <img
        class="h-56 select-none"
        src="../../../../../assets/img/decorl_for_right_side.svg"
      />
    </div> -->

    <!-- Контент страницы -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-12 lg:gap-16">
      <!-- Левая часть для фотографии -->
      <div class="col-span-5 w-full">
        <div class="sticky flex flex-col space-y-4 top-0 w-full">
          <!-- Если фотографии нет -->
          <ng-container
            *ngIf="
              cardApiService.person.content.images.length === 0;
              else photo
            "
          >
            <div
              class="
                bg-light
                flex flex-col
                justify-center
                card-img
                w-full
                items-center
              "
            >
              <img
                class="h-52 select-none"
                src="../../../../../assets/img/logo_gray.svg"
              />
            </div>
          </ng-container>

          <!-- Если фото есть -->
          <ng-template #photo>
            <div class="relative card-img w-full">
              <ng-container
                *ngFor="
                  let photo of cardApiService.person.content.images;
                  index as i
                "
              >
                <div
                  class="absolute h-full w-full bg-no-repeat bg-cover bg-center"
                  [ngStyle]="{
                    'background-image':
                      'url(' + cardApiService.activePhotoLink.src + ')'
                  }"
                ></div>
              </ng-container>
              <div class="absolute bottom-3 w-full">
                <div
                  class="
                    flex flex-row
                    justify-center
                    space-x-2.5
                    items-center
                    w-full
                  "
                >
                  <ng-container
                    *ngFor="
                      let item of cardApiService.person.content.images;
                      index as i
                    "
                  >
                    <button
                      class=""
                      [ngClass]="{
                        'dot-active': i === cardApiService.activePhotoLink.id,
                        dot: i !== cardApiService.activePhotoLink.id
                      }"
                      (click)="cardApiService.changePhoto(i)"
                    ></button>
                  </ng-container>
                  <!-- <button class="dot-active"></button>
                  <button class="dot"></button>
                  <button class="dot"></button> -->
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- Правая часть для отображения информации о человеке md:mt-14 lg:mt-20 -->
      <div class="md:col-span-7">
        <div class="grid grid-cols-1 lg:grid-cols-8">
          <div class="grid gap-y-10 md:gap-y-16 lg:col-span-7">
            <div class="flex flex-col space-y-4 w-full">
              <!-- Заголовок -->
              <div class="flex flex-col space-y-2.5 w-4/5 lg:w-3/4">
                <h3 class="ibm-plex-serif font-bold text-black text-4xl">
                  {{ cardApiService.person.full_name }}
                </h3>
                <p
                  class="open-sans text-black font-normal text-sm leading-snug"
                >
                  {{ cardApiService.person.rank_big }}
                </p>
              </div>

              <!-- Лейбы -->
              <div class="flex flex-row space-x-2.5">
                <app-label-light-brown>{{
                  cardApiService.person.rank_little
                }}</app-label-light-brown>

                <ng-container
                  *ngIf="cardApiService.person.world_war_one === '1'"
                >
                  <app-label-light-brown
                    >Участники 1 Мировой Войны</app-label-light-brown
                  >
                </ng-container>
              </div>
            </div>

            <!-- Блок вывода биографии если она есть -->
            <ng-container *ngIf="true">
              <div class="flex flex-col space-y-2 md:space-y-1.5 w-full">
                <h5
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  data-aos-offset="50"
                  class="ibm-plex-serif font-bold text-black text-2xl"
                >
                  Информация
                </h5>

                <ng-container
                  *ngIf="
                    cardApiService.person.content.biography !== null &&
                      cardApiService.person.content.biography !== '<p><br></p>';
                    else biographyEmpty
                  "
                >
                  <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="150"
                    data-aos-offset="50"
                    class="open-sans text-black font-normal text-base"
                    innerHTML="{{ cardApiService.person.content.biography }}"
                  >
                    {{ cardApiService.person.content.biography }}
                  </p>
                </ng-container>

                <ng-template #biographyEmpty>
                  <p
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="150"
                    data-aos-offset="50"
                    class="open-sans text-black font-normal text-base"
                  >
                    Информация отсутствует...
                  </p>
                </ng-template>
              </div>
            </ng-container>

            <!-- Вставка информации -->
            <div
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-offset="50"
              class="flex felx-row items-center space-x-2"
            >
              <img
                class="select-none"
                src="../../../../../assets/img/info_outline.svg"
              />

              <p class="open-sans text-gray font-normal text-sm">
                Если у вас есть информация, которой здесь нет, 
                <span
                  (click)="navigationService.navigateTo(externalRoutes.contact)"
                  class="
                    text-blue
                    cursor-pointer
                    hover:opacity-70
                    active:opacity-100
                    transition
                    duration-300
                    ease-in-out
                  "
                >
                  свяжитесь с нами</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-page-container>
</ng-container>

<ng-template #loading>
  <div class="flex flex-col justify-center items-center w-full h-screen">
    <img
      class="h-52 select-none animate-pulse"
      src="../../../../../assets/img/logo_gray.svg"
    />
  </div>
</ng-template>

<app-footer></app-footer>
