import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  /**
   * Метод для навигации между страницами
   * @param path куда средиректить пользователя
   */
  public navigateTo(path: string, params?: Object, notify?: boolean): void {
    notify && document.dispatchEvent(new CustomEvent('navigating', { bubbles: true }));
    this.router.navigate([path], { queryParams: params ? {...params} : null});
    this.scrollToTop();
  }

  /**
   * Метод для прокрутки страницы вверх
   * @param interval время прокрутки наверх
   */
  public scrollToTop(interval: number = 10): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, interval);
  }
}
