<div class="flex flex-row w-full items-start">
  <!-- Картинка -->
  <div
    class="fixed img-container hidden lg:block h-full animate__animated animate__fadeInLeft animate__fast"
  >
    <img
      class="h-screen w-full select-none"
      src="../../../../../assets/img/the_end.png"
    />

    <div
      class="absolute right-0 bottom-0 bg-black bg-opacity-70 py-4 px-6 mb-14"
    >
      <p class="open-sans text-white font-bold text-sm 2xl:text-base uppercase">
        Взятие зимнего дворца
      </p>
      <div class="flex flex-col space-y-0.5 mt-2">
        <p class="open-sans text-sm text-light-gray opacity-90">
          Автор: Олег Бернгардт
        </p>
        <p class="open-sans text-sm text-light-gray opacity-90">
          Дата: 1947 г.
        </p>
      </div>
    </div>
  </div>

  <!-- Контент -->
  <div
    class="absolute right-0 text-container mt-12 md:mt-14 lg:mt-9 pb-16 animate__animated animate__fadeInRight animate__fast"
  >
    <!-- Заголовок -->
    <app-head [pageTitle]="routerService.pageTitle"> </app-head>

    <!-- Текст -->
    <div class="mx-12 md:mx-28 lg:mx-24 2xl:mx-32 mt-10 lg:mt-12 2xl:mt-14">
      <p class="ibm-plex-serif text-black text-base select-none">
        Революционные события февраля 1917 года и последовавший за ними
        октябрький переворот, не миновали и 9-й гренадерский Сибирский полк.
        <br /><br />
        В результате окончательного развала фронта и подписания позорного
        «Брестского мира» частям 3-й гренадерской дивизии предписано было
        вернуться в Московский военный округ. В апреле 1918 года 9-й
        гренадерский Сибирский полк прибыл на свои квартиры в город Владимир,
        откуда в июле 1914 года он вышел в поход. <br /><br />
        Сразу по прибытии полка во Владимир начались мероприятия по увольнению
        запас офицеров и рядового состава. <br /><br />
        Безусловно, 9-й гренадерский полк – символ доблести, мужества и
        настоящего боевого духа.
      </p>
    </div>

    <app-bottom-navigation
      [prevLink]="externalRoutes.military_glory"
      prevTitle="Боевая слава"
    >
    </app-bottom-navigation>
  </div>
</div>
