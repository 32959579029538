<div [class]="className" (wheel)="scrollControl($event)" (click)="close()">
  <button (click)="close()" class="close-preview">
    <span class="panzoom-control-icon panzoom-control-close"></span>
  </button>
  <div class="panzoom__controls">
    <button (click)="zoomIn($event)">
      <span class="panzoom-control-icon panzoom-control-zoomIn"></span>
    </button>
    <button (click)="zoomOut($event)">
      <span class="panzoom-control-icon panzoom-control-zoomOut"></span>
    </button>
  </div>
  <div id="panzoom" class="panzoom">
    <img
      class="panzoom__content"
      [src]="image"
      alt=""
    />
  </div>
</div>
