import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnersPageComponent } from './partners-page/partners-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';
import { PartnerCardComponent } from './components/partner-card/partner-card.component';

@NgModule({
  declarations: [PartnersPageComponent, PartnerCardComponent],
  imports: [CommonModule, TemplatesModule, SharedModule],
})
export class PartnersModule {}
