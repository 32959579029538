import { createSelector } from '@ngrx/store';

import { IState } from '../index';
import { IMapRoutesState } from '../reducers/map-routes.reducers';

const selectedRoutes = (state: IState) => state.mapRoutes;

export const routesSelector = createSelector(
  selectedRoutes,
  (slice: IMapRoutesState) => ({ routes: slice.routes, isLoading: slice.isLoading, loaded: slice.loadIsRequired })
);
