import { Injectable } from '@angular/core';
import { APIservice } from 'src/app/core/api/api.service';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { IPerson } from '../classes/filter.classes';
import { HeritageArchiveFilterService } from './heritage-archive-filter.service';

@Injectable()
export class HeritageArchiveApiService {
  public persons: IPerson[] = [];
  public pageCounter = 0;

  public isWWOactive = false;
  public activeFilter = '';

  public links = [];

  constructor(
    private api: APIservice,
    private heritageArchiveFilterService: HeritageArchiveFilterService,
    private navigationService: NavigationService
  ) {}

  /**
   * Получить с сервера массив исторических личностей
   */
  public getHeritageArchiveData(filter: string = '', scrollTop = false): void {
    // tslint:disable-next-line: deprecation
    this.api.GET('/persons?sort=full_name' 
        + filter).subscribe((res) => {
      this.persons = res.data;
      this.links = res.meta.links;

      // Записываю кол-во страниц
      this.pageCounter = res.meta.last_page;

      // Удаляю фильтр войны
      if (filter === '') {
        this.isWWOactive = false;
      }

      // Авто прокрутка если он нужна
      if (scrollTop) {
        this.navigationService.scrollToTop(3);
      }

    });
  }

  /**
   * Метод отправки запроса фильтрации контента
   */
  public filterOnSubmit(wwo = false, scrollTop = false): void {
    if (this.heritageArchiveFilterService.filterForm.valid) {
      const url = new URL('http://home.com');

      // Условие добавления фильтра по имени
      if (
        this.heritageArchiveFilterService.filterForm.value.name !== '' &&
        this.heritageArchiveFilterService.filterForm.value.name !== null
      ) {
        url.searchParams.set(
          'filter[full_name]',
          this.heritageArchiveFilterService.filterForm.value.name
        );
      }

      // Условие добавления фильтра по рангу
      if (
        this.heritageArchiveFilterService.filterForm.value.rank !== '' &&
        this.heritageArchiveFilterService.filterForm.value.rank !== null &&
        this.heritageArchiveFilterService.filterForm.value.rank !==
          'Не выбрано...'
      ) {
        url.searchParams.set(
          'filter[rank_little]',
          this.heritageArchiveFilterService.filterForm.value.rank
        );
      }

      // Условие добавления фильтра по войне
      if (this.isWWOactive) {
        this.isWWOactive = true;
        url.searchParams.set('filter[world_war_one]', '1');
      }

      // Сохраняю текущий фильр
      this.activeFilter = url.search;
      // Делаю запрос на сервер с текущим фильтром
      this.getHeritageArchiveData('&' + url.search.substr(1), scrollTop);
    }
  }
}
