import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { RouterService } from 'src/app/core/router-navigations/router.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
})
export class NotFoundPageComponent implements OnInit {
  constructor(
    public navigationService: NavigationService,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute
  ) {
    this.routerService.setPageTitle(this.activatedRoute);
  }

  ngOnInit(): void {}
}
