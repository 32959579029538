<!-- Кнопка правителей для моб устройств -->
<div class="flex flex-col space-y-5 items-center">
  <div class="flex flex-col space-y-2">
    <ng-container *ngFor="let epoch of epochs; let i = index">
      <a
        [routerLink]="['/epoch']"
        [queryParams]="{order: epoch.order}"
        [ngClass]="{
          animate__fadeInRight: displayAllEpohs,
          animate__fadeOutRight: !displayAllEpohs,
          grayscale: epoch.order !== activeEpoch
        }"
        (click)="displayAllEpohs = false"
        class="
          {{ 'epoh_' + (epochs.length - i) }}
          h-12
          w-12
          rounded-full
          bg-no-repeat bg-cover bg-center
          animate__animated animate__faster
          filter
        "
        [ngStyle]="{
          'background-image': 'url(' + epoch.content.mini_image + ')'
        }"
      ></a>
    </ng-container>
  </div>

  <!-- Активная эпоха -->
  <ng-container *ngFor="let epoch of epochs; let i = index">
    <ng-container *ngIf="i === activeEpoch - 1">
      <div
        (click)="displayAllEpohs = !displayAllEpohs"
        (blur)="displayAllEpohs = false"
        class="
          bg-no-repeat bg-cover bg-center
          border-2 border-primary
          h-12
          w-12
          rounded-full
          cursor-pointer
        "
        [ngStyle]="{
          'background-image': 'url(' + epoch.content.mini_image + ')'
        }"
      ></div>
    </ng-container>
  </ng-container>
</div>
