import { Action } from '@ngrx/store';

interface IEpochContent {
  description: string;
  event_points: any;
  route: any;
  menu_name: string;
  mini_image: string;
  full_image: string;
  route_file: string;
}

export interface IEpoch {
  content: IEpochContent;
  events: Array<any>;
  name: string;
  order: number;
  persons: Array<any>;
}

export enum EpochActions {
  LoadEpochs = '[Epoch] Load Epochs',
  LoadEpochsSuccess = '[Epoch] Load Epochs Successs',
  LoadEpochsError = '[Epoch] Load Epochs Error',
  LoadEpochsProgress = '[Epoch] Load Progress',
  LoadEpochsAsync = '[Epoch] Load Async',
}

export class LoadEpochs implements Action {
  readonly type = EpochActions.LoadEpochs;
}

export class LoadEpochsSuccess implements Action {
  readonly type = EpochActions.LoadEpochsSuccess;

  constructor(public payload: { epochs: IEpoch[] }) {}
}

export class LoadEpochsError implements Action {
  readonly type = EpochActions.LoadEpochsError;

  constructor(public payload: { error?: Error }) {}
}

export class LoadEpochsProgress implements Action {
  readonly type = EpochActions.LoadEpochsProgress

  constructor(public payload: { progress: number, chunk?: number }) {}
}

export class LoadEpochsAsync {
  readonly type = EpochActions.LoadEpochsAsync
}

export type EpochsActionsUnion = LoadEpochs | LoadEpochsSuccess | LoadEpochsError | LoadEpochsProgress | LoadEpochsAsync;
