<ul class="collapsible">
  <li id="persons" class="active w-full">
    <div class="collapsible-header py-5">
      <div
        [ngClass]="{
          'pb-6': collapsStatus
        }"
        class="flex flex-row items-center justify-start w-full"
      >
        <h6 class="ibm-plex-serif font-bold text-black uppercase text-xl 2xl:text-2xl w-1/2">
          Персоны
        </h6>
        <div class="flex flex-row justify-end w-1/2">
          <img
            id="arrow"
            src="../../../../../assets/icons/arrow_drop_down.svg"
            class="
              select-none
              opacity-50
              transform
              transition
              duration-300
              ease-in-out
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="collapsible-body flex flex-col space-y-5 w-full">
      <!-- Раздел 1 -->
      <div class="flex flex-row items-start w-full">
        <div class="flex flex-col space-y-3 w-full">
          <ng-container
            *ngFor="let person of contentService.epoch.persons; let i = index"
          >
            <ng-container *ngIf="person.rank_little === 'Офицер'">
              <div class="flex flex-col space-y-1 flex-wrap w-full">
                <p class="text-sm 2xl:text-base text-black mr-1.5 leading-tight">
                  {{ person.rank_big }}
                </p>

                <a
                  (click)="navigationService.scrollToTop(0)"
                  [href]="'card?id=' + person.id"
                  class="text-sm 2xl:text-base text-blue leading-tight hover:underline"
                >
                  {{ person.full_name }}
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </li>
</ul>
