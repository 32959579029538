// tslint:disable-next-line: variable-name
export const heritage_archive = 'heritage-archive';
// tslint:disable-next-line: variable-name
export const heritage_archive_card = 'card';
export const contact = 'contact';
export const epoch = 'epoch';
export const about = 'about';
export const acquire = 'acquire';

// Информационные страницы
export const foundation = 'foundation';
// tslint:disable-next-line: variable-name
export const military_glory = 'military-glory';
export const partners = 'partners';
// tslint:disable-next-line: variable-name
export const end_of_activity = 'end-of-activity';

export class ExternalRoutes {
  // tslint:disable-next-line: variable-name
  public readonly heritage_archive = heritage_archive;
  // tslint:disable-next-line: variable-name
  public readonly heritage_archive_card = heritage_archive;
  public readonly contact = contact;
  public readonly epoch = epoch;
  public readonly foundation = foundation;
  public readonly partners = partners;

  public readonly military_glory = military_glory;
  public readonly end_of_activity = end_of_activity;
  public readonly about = about;
  public readonly acquire = acquire;
}
