import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { ExternalRoutes } from 'src/app/routes/external.routes';
import { EpochApiService } from '../../pages/epoch/services/epoch-api.service';
import { Store, select } from '@ngrx/store';
import { IState } from 'src/app/store';
import { selectAllEpochs, isLoadingEpochs } from 'src/app/store/selectors/epoch.selectors';
import { IEpoch } from 'src/app/store/actions/epoch.actions';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  public epochs: IEpoch[] = [];
  public isLoading: boolean = true;

  private selectEpochs$ = this._store.pipe(select(selectAllEpochs));
  private isLoading$ = this._store.pipe(select(isLoadingEpochs));

  constructor(
    public epochApiService: EpochApiService,
    public navigationService: NavigationService,
    public externalRoutes: ExternalRoutes,
    private _store: Store<IState>,
  ) {

    this.selectEpochs$.subscribe((val) => this.epochs = val)
    this.isLoading$.subscribe((val) => this.isLoading = val)
    // Появление навигационного меню
    document.addEventListener('DOMContentLoaded', () => {
      const elems = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elems, {});
    });

    // Разворачивающиеся списки меню
    document.addEventListener('DOMContentLoaded', () => {
      const elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems, {
        accordion: false,
      });
    });

  }

  ngOnInit(): void {

  }

  ngOnChanges() {
  }
}
