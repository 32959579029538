import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { HeritageArchiveApiService } from '../../services/heritage-archive.service';

@Component({
  selector: 'app-archive-pagination',
  templateUrl: './archive-pagination.component.html',
})
export class ArchivePaginationComponent implements OnInit {
  @Input() links = [];

  constructor(
    public heritageArchiveApiService: HeritageArchiveApiService,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {}
}
