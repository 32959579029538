import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { ActivatedRoute} from '@angular/router';
import { PreloaderService } from 'src/app/core/preloader/preloader.service';
import { RouterService } from 'src/app/core/router-navigations/router.service';;
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  constructor (
    public preloaderService: PreloaderService,
    public navigationService: NavigationService,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute
    ) {
    this.routerService.setPageTitle(this.activatedRoute);
  }

  ngOnInit(): void {
    // let video: HTMLMediaElement | any = document.getElementById("vid");
    // if (video != null) {
    //   video.addEventListener('canplay', function() {
    //     video.play();
    //   })
    // }
    document.addEventListener('DOMContentLoaded', () => {
      let video: HTMLMediaElement | any = document.getElementById("vid");
      if (video != null) {
        video.addEventListener('canplay', function() {
          video.play();
        })
      }
    });
  }
}
