<div class="relative flex flex-col space-y-2.5 w-full pb-5">
  <label [for]="fieldName" class="open-sans font-semibold text-black text-sm">{{
    fieldLabel
  }}</label>
  <div
    [ngClass]="{
      'z-10':
        formsService.activeFieldAction !== 'focus' &&
        formsService.activeFieldID === fieldName,
      'border-light-gray': !form.get(fieldName)?.touched,
      'border-blue':
        formsService.activeFieldAction === 'focus' &&
        formsService.activeFieldID === fieldName,
      'border-red-500':
        form.get(fieldName)?.invalid && form.get(fieldName)?.touched,
      'border-green-600':
        form.get(fieldName)?.valid &&
        form.get(fieldName)?.touched &&
        form.value[fieldName] !== '' &&
        form.value[fieldName] !== 'Выберите дату...' &&
        form.value[fieldName] !== 'Не выбрано...'
    }"
    class="flex flex-row items-center border bg-white rounded-md w-full px-4 transition duration-300 ease-in-out"
  >
    <ng-content></ng-content>
  </div>
  <div class="absolute bottom-0 select-none">
    <!-- Если человек не заполнил поле -->
    <ng-container
      *ngIf="form.get(fieldName)?.invalid && form.get(fieldName)?.touched"
    >
      <p
        class="open-sans text-xs text-red-600 animate__animated animate__slideInDown animate__faster"
      >
        Это обязательное поле
      </p>
    </ng-container>

    <!-- Если все верно заполнил -->
    <ng-container
      *ngIf="
        form.get(fieldName)?.valid &&
        form.get(fieldName)?.touched &&
        form.value[fieldName] !== '' &&
        form.value[fieldName] !== 'Выберите дату...' &&
        form.value[fieldName] !== 'Не выбрано...'
      "
    >
      <p
        class="open-sans text-xs text-green animate__animated animate__slideInDown animate__faster"
      >
        Поле заполненно верно
      </p>
    </ng-container>
  </div>
</div>
