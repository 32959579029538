import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-center-page-head',
  templateUrl: './center-page-head.component.html',
})
export class CenterPageHeadComponent implements OnInit {
  @Input() pageTitle = '';
  @Input() pageDescription = '';

  constructor() {}

  ngOnInit(): void {}
}
