import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'btn-fill-fixed',
  templateUrl: './btn-fill-fixed.component.html',
})
export class BtnFillFixedComponent implements OnInit {
  @Input() type = 'button';

  constructor() {}

  ngOnInit(): void {}
}
