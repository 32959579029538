import { Component, OnInit } from '@angular/core';
import { preoladerAnimation } from 'src/app/animation/preloader.animation';
import { PreloaderService } from 'src/app/core/preloader/preloader.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.css'],
  animations: [preoladerAnimation],
})
export class PreloaderComponent implements OnInit {
  constructor(public preloaderService: PreloaderService) {}

  ngOnInit(): void {
    document.body.classList.add('overflow-hidden');
  }
}
