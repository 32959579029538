import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/core/router-navigations/router.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
})
export class ContactPageComponent implements OnInit {
  public pageTitle = '';

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute
  ) {
    /* Запрашиваю информацию по персоне */
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      // this.cardApiService.getPersonInfo(params.id);
      if (params.type === 'buy_book') {
        this.pageTitle = 'Купить книгу';
        this.titleService.setTitle(this.pageTitle);
      } else {
        this.pageTitle = 'Контакты';
        this.routerService.setPageTitle(this.activatedRoute);
      }
      // console.log(params.type);
    });
  }

  ngOnInit(): void {}
}
