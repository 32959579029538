import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { TemplatesModule } from '../templates/templates.module';
import { PreloaderComponent } from './preloader/preloader.component';
import { InputComponent } from './input/input.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { UiModule } from 'src/app/ui/ui.module';
import { CenterPageHeadComponent } from './center-page-head/center-page-head.component';

@NgModule({
  declarations: [
    FooterComponent,
    PreloaderComponent,
    InputComponent,
    MenuComponent,
    CenterPageHeadComponent,
  ],
  imports: [CommonModule, TemplatesModule, RouterModule, UiModule],
  exports: [FooterComponent, PreloaderComponent, InputComponent, MenuComponent, CenterPageHeadComponent],
})
export class ComponentsModule {}
