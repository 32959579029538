<button
  [type]="type"
  [ngClass]="{
    'w-full': full_width,
    'px-6': !full_width
  }"
  class="btn-fill py-3.5 rounded-full text-white text-sm font-medium hover:opacity-80 transition duration-300 ease-in-out"
>
  <ng-content></ng-content>
</button>
