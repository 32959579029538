import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
})
export class ContentComponent implements OnInit {
  @Input() id = 1;
  @Input() action = 1;
  @Input() title = '';
  @Input() date = '';
  @Input() description = '';
  @Input() audio = '';
  @Output() newImagePreview = new EventEmitter<string>();

  constructor() {
    // Toggle red color
  }

  ngOnInit(): void {}

  onClick(ev: MouseEvent): void {
    const element = ev.target as HTMLImageElement;
    if (element.tagName !== 'IMG') {
      return
    }
    ev.preventDefault();
    ev.stopPropagation();
    this.newImagePreview.emit(element.src);
  }
}
