import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'btn-stroke-fixed',
  templateUrl: './btn-stroke-fixed.component.html',
})
export class BtnStrokeFixedComponent implements OnInit {
  @Input() type = 'button';
  constructor() {}

  ngOnInit(): void {}
}
