import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';
import { LettersService } from './services/letters.service';
import { LetterFormComponent } from './components/letter-form/letter-form.component';

@NgModule({
  declarations: [ContactPageComponent, LetterFormComponent],
  imports: [CommonModule, TemplatesModule, SharedModule, ReactiveFormsModule],
  providers: [LettersService],
})
export class ContactModule {}
