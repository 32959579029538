<div class="flex flex-row w-full items-start">
  <!-- Картинка -->
  <div
    class="
      fixed
      img-container
      hidden
      lg:block
      animate__animated animate__fadeInLeft animate__fast
    "
  >
    <div class="flex flex-col justify-center items-center h-screen w-full">
      <img
        class="select-none w-3/5"
        src="../../../../../assets/img/author.png"
      />
    </div>
  </div>

  <!-- Контент -->
  <div
    class="
      absolute
      right-0
      text-container
      mt-12
      md:mt-14
      lg:mt-9
      pb-16
      animate__animated animate__fadeInRight animate__fast
    "
  >
    <div class="grid grid-cols-1 gap-y-20 place-items-center">
      <!-- Автор -->
      <div>
        <!-- Заголовок -->
        <div class="flex flex-col items-center justify-center w-full">
          <p
            class="
              open-sans
              text-brown
              font-semibold
              text-sm
              2xl:text-base
              text-center
            "
          >
            О книге
          </p>
          <h3
            class="
              ibm-plex-serif
              font-bold
              text-black text-center text-4xl
              pt-1
              2xl:pt-1.5
              pb-3.5
              2xl:pb-4
              w-1/2
            "
          >
            Автор
          </h3>
          <img
            class="select-none transform rotate-180 h-7 2xl:h-8"
            src="../../../../../assets/img/monogram-standart.svg"
          />
        </div>

        <!-- Текст -->
        <div class="mx-10 md:mx-28 lg:mx-24 2xl:mx-32 mt-10 lg:mt-12 2xl:mt-14">
          <p class="ibm-plex-serif text-black text-base select-none">
            Исаков Александр Владимирович,потомственный военный. 
            В 1978–2004 годах — в Вооруженных Силах России. Окончил военное училище, 
            видовую академию, Военную академию Генерального штаба ВС РФ. Доктор юридических наук.
          </p>
        </div>
      </div>

      <!-- О книге -->
      <div>
        <!-- Заголовок -->
        <div class="flex flex-col items-center justify-center w-full">
          <h3
            class="
              ibm-plex-serif
              font-bold
              text-black text-center text-4xl
              pt-1
              2xl:pt-1.5
              pb-3.5
              2xl:pb-4
              w-1/2
            "
          >
            О чём книга?
          </h3>
          <img
            class="select-none transform rotate-180 h-7 2xl:h-8"
            src="../../../../../assets/img/monogram-standart.svg"
          />
        </div>

        <!-- Текст -->
        <div class="mx-10 md:mx-28 lg:mx-24 2xl:mx-32 mt-10 lg:mt-12 2xl:mt-14">
          <p class="ibm-plex-serif text-black text-base select-none">
            Труд посвящен истории одной из самых славных воинских частей Русской
            армии — 9-го гренадерского Сибирского Его Императорского Высочества
            Великого князя Николая Николаевича Старшего полка. Старейший
            «петровский» полк за свою более чем двухсотлетнюю историю
            поучаствовал едва ли не во всех войнах России — Северной войне
            1700–1721 гг., нескольких русско-турецких войнах XVIII–XIX вв.,
            Отечественной войне 1812 г. и заграничных походах Русской армии
            1813–1814 гг., Первой мировой войне 1914–1918 гг. Пройдя долгий
            боевой путь с Русской армией, 9-й гренадерский Сибирский полк и
            завершил его вместе с ней, сгинув в угаре революции в 1918 г.
            Многолетняя работа автора по выявлению письменных и материальных
            свидетельств истории полка в архивах, библиотеках, музеях и частных
            коллекциях позволили составить подробную, нередко даже поденную
            хронику этой воинской части, чья история — плоть от плоти военной
            истории Отечества в целом. Поэтому книга является не только данью
            памяти офицерам и нижним чинам Сибирского полка, тысячи имен которых
            оживают на ее страницах, но одновременно представляет собой ценный
            вклад в историографию военной истории Отечества XVIII — начала ХХ
            вв.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
