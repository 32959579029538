<!-- Заголовок -->
<div class="flex flex-col items-center justify-center w-full">
  <p
    class="open-sans text-brown font-semibold text-sm 2xl:text-base text-center"
  >
    О полку
  </p>
  <h3
    class="ibm-plex-serif font-bold text-black text-center text-4xl pt-1 2xl:pt-1.5 pb-3.5 2xl:pb-4 w-full md:w-1/2"
  >
    {{ pageTitle }}
  </h3>
  <img
    class="select-none transform rotate-180 h-7 2xl:h-8"
    src="../../../../../assets/img/monogram-standart.svg"
  />
</div>
