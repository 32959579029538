<div
  class="grid grid-cols-1 w-full border-t border-dashed border-gray-300 pt-8"
>
  <!-- Текст заголовка -->
  <div class="flex flex-row justify-center w-full">
    <h5
      class="ibm-plex-serif font-bold text-xl 2xl:text-2xl text-black text-center uppercase"
    >
      Условные обозначения
    </h5>
  </div>

  <!-- Список обозначений -->
  <div class="flex flex-col gap-y-2.5 w-full mt-5">
    <!-- Обозначение движения -->
    <div class="flex flex-row space-x-2.5 items-center">
      <div class="p-2.5 rounded-full" [style.background-color]="color"></div>
      <p class="text-sm 2xl:text-base w-full text-black">
        Движение основных сил 9-го гренадерского Сибирского Его Императорского
        Величества Великого князя Николая Николаевича Старшего полка.
      </p>
    </div>

    <!-- Обозначение развернутого события -->
    <div class="flex flex-row space-x-2.5 items-center">
      <img [src]="src" class="h-5" />
      <p class="text-sm 2xl:text-base w-full text-black">
        Сражение полка.
      </p>
    </div>

    <!-- Обозначения обычного события -->
    <div class="flex flex-row space-x-2.5 items-center">
      <img src="../../../../../assets/icons/event.svg" class="h-5" />
      <p class="text-sm 2xl:text-base w-full text-black">Важное событие в жизни полка.</p>
    </div>
  </div>
</div>
