import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'app-combat-form-modal',
  templateUrl: './combat-form-modal.component.html',
})
export class CombatFormModalComponent implements OnInit {
  @ViewChild('modalForm') modalForm!: ElementRef;
  @Input() events: any = 0;
  @Input() epoch = '';
  @Input() order = 1;
  private instance = M.Modal.init(this.modalForm?.nativeElement);
  @Input() isOpen = this.instance?.isOpen || false;
  @Input() onSelection = (ev: Event) => {};
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  constructor(public contentService: ContentService) {
    this.onClickOverlay = this.onClickOverlay.bind(this);
  }

  ngOnInit(): void {
    document.addEventListener('click', this.onClickOverlay);
  }

  ngAfterViewInit() {
    this.instance =  M.Modal.init(this.modalForm.nativeElement);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onClickOverlay);
  }

  onButtonClick() {
    this.isOpen ? this.instance?.close() : this.instance?.open();
    this.isOpen = this.instance.isOpen;
  }

  onClickOverlay(ev: Event) {
    const withinBoundaries = ev.composedPath().includes(this.modalForm.nativeElement);
    const elem = ev.target as HTMLElement;
    const isOverlay = elem.classList.contains('modal-overlay');

    if (!withinBoundaries && isOverlay) {
      this.instance.close();
      this.isOpen = this.instance.isOpen;
      this.onClose.emit(true);
    }
  }

  onHrefClick(ev: Event, idetifier: string) {
    ev.preventDefault();
    const elem = document.getElementById(idetifier);
    if (elem) {
      const y = elem.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth',
      })
    }
  }
}
