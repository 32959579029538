import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreloaderService } from 'src/app/core/preloader/preloader.service';
import { RouterService } from 'src/app/core/router-navigations/router.service';
import { ContentService } from '../services/content.service';
import { Store, select } from '@ngrx/store';
import { IState } from '../../../../store';
import { selectAllEpochs } from '../../../../store/selectors/epoch.selectors';
import { routesSelector } from 'src/app/store/selectors/map-routes.selectors';
import { IEpoch } from 'src/app/store/actions/epoch.actions';
import { Subscription } from 'rxjs';
import { IRoutes, LoadMapRoutes } from 'src/app/store/actions/map-routes.actions';

@Component({
  selector: 'app-epoch-page',
  templateUrl: './epoch-page.component.html',
})
export class EpochPageComponent implements OnInit {
  public epochData: any;
  public imagePreview = '';
  public order = 1;
  public isModalOpen = false;
  public epochsList: IEpoch[] = [];

  private mapRoutes: IRoutes[] = [];
  private requestEventName = 'epochShowRequest';
  private requestChangeEpoch = 'requestChangeEpoch';
  private requestRemoveEpoch = 'requestRemoveEpoch';
  private requestOnInit = 'epochComponentInit';
  private isRoutesLoaded = false;

  private subscription: Subscription = new Subscription();
  private nestedSubscription: Subscription = new Subscription();

  constructor(
    public preloaderService: PreloaderService,
    public contentService: ContentService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute,
    private _store: Store<IState>,
    public host: ElementRef
  ) {
    document.addEventListener('DOMContentLoaded', () => {
      const elems = document.querySelectorAll('.modal');
      M.Modal.init(elems, {});
    });

    this.findRoute = this.findRoute.bind(this);
    this.getRoutesData = this.getRoutesData.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.selectionListener = this.selectionListener.bind(this);
    this.createSubscription = this.createSubscription.bind(this);

    this.routerService.setPageTitle(this.activatedRoute);
  }

  ngOnInit(): void {
    this.subscription = this.createSubscription();
    this.host.nativeElement.dispatchEvent(
      this.createEvent(this.requestOnInit, null)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.nestedSubscription.unsubscribe();
    this.host.nativeElement.dispatchEvent(
      this.createEvent(this.requestRemoveEpoch, null)
    );
    document.removeEventListener('selectionchange', this.selectionListener);
  }

  public openCombatWindow(): void {
    const win = document.getElementById('combat-window');
    const content = document.getElementById('content-window');

    win?.classList.toggle('hidden');
    content?.classList.toggle('hidden');
  }

  public toogleEpochContent(
    map: HTMLDivElement,
    content: HTMLDivElement,
    action: string
  ): void {
    if (action === 'open_map') {
      map.classList.remove('hidden');
      content.classList.add('hidden');
    }
  }

  public setImagePreview(src: string) {
    src && console.log('new imagePreview requested');
    this.imagePreview = src;
  }

  public onContextMenu(ev: Event) {
    const element = ev.target as HTMLElement;
    if (element.tagName === 'IMG') {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  public closeModal() {
    this.isModalOpen = false;
  }

  public openModal() {
    this.isModalOpen = true;
  }

  public onSelection(ev: Event) {
    ev.preventDefault();
    document.addEventListener('selectionchange', this.selectionListener)
  }

  private getRoutesData() {
    const activeRoute = this.mapRoutes.find((item) => this.findRoute(item));
    const otherRoutes = this.mapRoutes.filter((item) => !this.findRoute(item));
    return { activeRoute, otherRoutes }
  }

  private findRoute(route: {}) {
    return Object.keys(route).find((key) => key.split('_')[0] === String(this.order));
  }

  private createEvent( eventName: string, data: any) {
    return new CustomEvent( eventName ,{
      bubbles: true,
      detail: {
        data: data,
      },
    })
  }

  private selectionListener() {
    document.getSelection()?.empty();
  }

  private createSubscription() {
    return this._store.pipe(select(selectAllEpochs)).subscribe((val) => {
      this.epochsList = val;
        
      const paramSubscription = this.route.queryParams.subscribe((params) => {
        if (this.order !== Number(params.order)) {
          this.host.nativeElement.dispatchEvent(
            this.createEvent(this.requestChangeEpoch, {})
          )
        }
        this.order = this.order !== Number(params.order) ? Number(params.order) : this.order;
        const currentEpoch = val.find((epoch: IEpoch) => Number(epoch.order) === Number(params.order));
        if (currentEpoch) {
          this.contentService.setEpochData(currentEpoch);
          this.epochData = currentEpoch;
        }
      })
  
      if (!this.isRoutesLoaded) {
        this._store.dispatch(new LoadMapRoutes(val));
      }
  
      const mapRoutesSubscription = this._store.pipe((select(routesSelector))).subscribe((res) => {
        this.isRoutesLoaded = !res.isLoading;
        if (res.routes) {
          this.mapRoutes = res.routes;
          if (!res.isLoading && !res.loaded && val.length) {
            this.host.nativeElement.dispatchEvent(
              this.createEvent(this.requestEventName, { routes: res.routes, epochs: val })
            )
          }
        }
      })
  
      this.nestedSubscription.add(paramSubscription);
      this.nestedSubscription.add(mapRoutesSubscription);
    });  
  }
}
