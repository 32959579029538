import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { ExternalRoutes } from 'src/app/routes/external.routes';
import { CardApiService } from '../service/card-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-card-page',
  templateUrl: './card-page.component.html',
})
export class CardPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public cardApiService: CardApiService,
    public navigationService: NavigationService,
    public externalRoutes: ExternalRoutes
  ) {
    /* Запрашиваю информацию по персоне */
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      this.cardApiService.getPersonInfo(params.id);
    });
  }

  ngOnInit(): void {}
}
