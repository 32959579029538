<div id="combat-form-modal" class="modal bg-white max-w-2xl h-auto xl:h-full" #modalForm (selectstart)="onSelection($event)">
  <div
    class="flex flex-col space-y-6 justify-center items-center w-full px-8 py-6"
  >
    <div class="flex flex-col items-center space-y-2.5 w-full">
      <h5 class="ibm-plex-serif text-3xl font-bold text-black text-center">
        Содержание
      </h5>
      <p class="text-sm open-sans text-black">
        Эпоха <span class="font-bold">{{ epoch }}</span>
      </p>
    </div>

    <!-- Оглавление -->
    <div class="flex flex-col space-y-5 w-full mt-28">
      <!-- Введениe -->
      <a
        [href]="'#' + order + '_0'"
        (click)="onHrefClick($event, order + '_0')"
        class="
          flex flex-row
          space-x-3.5
          items-center
          w-full
          h-12
          hover:opacity-100
          transition
          duration-300
          ease-in-out
          cursor-pointer
        "
        [ngClass]="{
          'opacity-70': contentService.getActiveContentBlock() !== 'intro'
        }"
      >
        <div
          class="h-full w-0.5"
          [ngClass]="{
            'bg-primary': contentService.getActiveContentBlock() === 'intro'
          }"
        ></div>
        <div class="flex flex-col space-y-1 w-full">
          <p class="open-sans text-sm text-brown font-bold uppercase">
            Введение и персоны
          </p>
        </div>
      </a>

      <ng-container *ngIf="events !== 0">
        <ng-container *ngFor="let event of events; let i = index">
          <!-- Список событий -->
          <a
          [href]="'#' + event.identrifier"
          target="_self"
          (click)="onHrefClick($event, event.identrifier)"
          class="
            scroll-to
            flex flex-row
            space-x-3.5
            items-center
            w-full
            h-12
            hover:opacity-100
            transition
            duration-300
            ease-in-out
            cursor-pointer
          "
          [ngClass]="{
            'opacity-70': contentService.getActiveContentBlock() !== i + 1
          }"
          >
            <div
              class="h-full w-0.5"
              [ngClass]="{
                'bg-primary': contentService.getActiveContentBlock() === i + 1
              }"
            ></div>
            <div class="flex flex-col space-y-1.5 w-full">
              <p class="open-sans text-sm text-brown font-bold uppercase">
                {{ event.date }}
              </p>
              <p class="open-sans text-sm text-black">
                {{ event?.content?.name }}
              </p>
            </div>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>