import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  public pageTitle = '';

  constructor(private titleService: Title, private router: Router) {}

  public setPageTitle(activatedRoute: ActivatedRoute): void {
    // tslint:disable-next-line: deprecation
    this.router.events.subscribe({
      next: (event: any) => {
        if (event instanceof NavigationEnd) {
          this.titleService.setTitle(activatedRoute.snapshot.data.title);
        }
      },
      error: (err: any) => {
        console.log(err);
        this.router.navigate(['']);
      },
    });

    this.pageTitle = activatedRoute.snapshot.data.title;
  }
}
