<ng-container *ngIf="preloaderService.isPreloaderShow">
  <preloader></preloader>
</ng-container>

<div class="block relative w-full h-screen" style="background-color: #000000;">
  <!-- Информационная вставка -->
  <div class="absolute w-full bottom-0 z-10">
    <div class="flex flex-row justify-center w-full mb-24 hidden">
      <div class="flex flex-col space-y-2 bg-black bg-opacity-50 p-5 w-2/5">

        <p class="text-center text-gold text-xl uppercase leading-snug">
          История 9-го гренадерского Сибирского Его Императорского Величества
          Великого князя Николая Николаевича Старшего полка
        </p>

        <p class="text-center text-white text-sm leading-snug">
          Проект посвящен памяти всем участникам великого 9-го Гренадерского
          Сибирского полка. О мужестве, чести и истинной любви к родному
          отечеству. В назидание потомкам.
        </p>
      </div>
    </div>
  </div>

  <!-- <div>
    <video id="vid" muted autoplay playsinline onloadedmetadata="this.muted = true"
    class="absolute bottom-0 right-0 z-0 block object-fill w-full h-full"
    [src]="preloaderService.fakeVideo"
    >
  </video>
  </div> -->

  <video id="vid" muted autoplay playsinline onloadedmetadata="this.muted = true"
    class="absolute bottom-0 right-0 min-w-full min-h-full z-0 block"
    [src]="preloaderService.fakeVideo"
  >
  </video>

  <div class="absolute bottom-0 z-10 w-full select-none">
    <div class="flex flex-row justify-center w-full pb-1">
      <img class="
          h-10
          2xl:h-12
          animate__animated
          animate__fadeInDown
          animate__slower
          animate__infinite
          infinite
        " src="../../../../../assets/icons/expand_more.svg" />
    </div>
  </div>
</div>

<!-- mobile -->
<div class="xl:hidden relative w-full" style="background-color: #000000;">
  <div class="
      hidden
      md:absolute
      right-0
      md:mr-8
      mt-8
      flex flex-col
      md:flex-row
      space-y-10
      md:space-y-0 md:space-x-5
      items-center
      z-10
    ">
    <img src="../../../../../assets/img/awards.svg" />
    <img src="../../../../../assets/img/awards.svg" />
    <img src="../../../../../assets/img/awards.svg" />
  </div>

  <!-- Информационная вставка -->
</div>

<!-- Секция с правителями -->
<div class="w-full h-auto bg-dark-brown pt-8 lg:pt-20 xl:pt-28 md:pb-16 lg:pb-0 xl:pb-20">
  <!-- Блок Петра I Алексеевича (левосторонний блок) -->
  <a
  (click)="navigationService.navigateTo('/epoch', {order:1}, true)"
  >
    <div class="
    relative
    flex flex-col
    lg:flex-row
    items-center
    justify-center
    lg:items-start
    w-full
    pt-20
    cursor-pointer
  ">

      <!-- Левая часть -->

      <div class="
      flex flex-row
      justify-center
      lg:justify-end
      w-full
      lg:w-1/2
      pb-6
      lg:pb-0
    ">
        <div class="relative lg:absolute bottom-0 z-10">
          <img data-aos="fade-up" data-aos-offset="100" class="h-64 md:h-72 lg:h-80 select-none"
            src="../../../../../assets/img/port/port_1.png" />
        </div>
      </div>

      <!-- Центральная часть -->
      <div class="
      hidden
      lg:flex
      absolute
      center-line
      flex-col
      items-center
      h-full
      z-20
    ">
        <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-offset="-180"
          class="center-dott bg-white rounded-full w-3 h-3"></div>
        <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-offset="-180" class="line bg-white h-full">
        </div>
      </div>

      <!-- Правая часть -->
      <div class="
      flex flex-col
      justify-center
      items-center
      lg:items-start
      space-y-2
      pl-10
      w-full
      lg:w-1/2
    ">
        <h3 data-aos="fade-up" data-aos-offset="100"
          class="hidden lg:block ibm-plex-serif font-bold text-gold text-4xl">
          1682<br />1725
        </h3>
        <h3 data-aos="fade-up" data-aos-offset="100" class="lg:hidden ibm-plex-serif font-bold text-gold text-4xl">
          1682-1725
        </h3>
        <p data-aos="fade-up" data-aos-delay="50" data-aos-offset="100" class="
        ibm-plex-serif
        text-white text-center
        lg:text-left
        font-semibold
        text-lg
        w-1/2
        md:w-2/5
        lg:w-1/2
        2xl:w-2/6
      ">
          Правление царя и императора Петра I Алексеевича
        </p>
        <p data-aos="fade-up" data-aos-delay="100" data-aos-offset="100" class="
        open-sans
        text-base text-white text-center
        lg:text-left
        text-opacity-70
        w-4/5
        px-2
        md:px-0 md:w-3/5
        lg:w-4/5
        xl:w-3/5
      ">
          Герой нашего повествования 9-й гренадерский Сибирский полк был образован «1700 года, июня 25 дня» как 
          «пехотный Ирика фон Вердена полк, в составе 1-й гренадерской и 10-й фузилерных рот». С этого началась почти 
          двухсотлетняя славная боевая история полка. В первые же годы своего создания полк активно участвовал в Северной войне, 
          в том числе в знаменитой Полтавской битве.
        </p>
      </div>
    </div>
  </a>


  <!-- Блок Анны Иоанновны (правосторонний блок) -->
  <a 
  (click)="navigationService.navigateTo('/epoch', {order:2}, true)"
   >
    <app-block-right-side dateFrom="1730" dateTo="1740" title="Правление императрицы Анны Иоанновны" description="В правление императрицы Анны Иоанновны Сибирский полк участвовал в нескольких кампаниях:
    в войне за польское наследство (1733–1735), Русско-турецкой войне (1735–1739)" imgSrc="../../../../../assets/img/port/port_2.png">
    </app-block-right-side>
  </a>

  <!-- Блок Елизаветы Петровны (левосторонний блок) -->
  <a 
  (click)="navigationService.navigateTo('/epoch', {order:3}, true)"
  >
    <app-block-left-side dateFrom="1741" dateTo="1761" title="Правление императрицы Елизаветы Петровны" description="В правление дочери Петра I Елизаветы Петровны полк участвовал в Русско-шведской (1741–1743) и Семилетней (1756–1763) войнах" imgSrc="../../../../../assets/img/port/port_3.png">
    </app-block-left-side>
  </a>

  <!-- Блок II Алексеевны Великой (правосторонний блок) -->
  <a 
  (click)="navigationService.navigateTo('/epoch', {order:4}, true)"
  >
    <app-block-right-side dateFrom="1762" dateTo="1801"
      title="Правление императорицы Екатерины II Алексеевны Великой / Правление императора Павла I" description="Во второй половине XVIII – начале XIX вв. Сибирский полк без устали сражался против главных недругов России того времени: участвовал в трех экспедициях против польских повстанцев (1768, 1792 и 1794 гг.) и сразу в трех Русско-турецких войнах (1769–1774; 1787–1791)" imgSrc="../../../../../assets/img/port/port_4.png">
    </app-block-right-side>
  </a>

  <!-- Блок Александра I Павловича (левосторонний блок) -->
  <a 
    (click)="navigationService.navigateTo('/epoch', {order:5}, true)"
  >
  	<app-block-left-side dateFrom="1801" dateTo="1825" title="Правление императора Александра I Павловича" description="Правление императора Александра I ознаменовалось для Сибирского полка активным участием в Наполеоновских войнах. С первых дней полк принимал участие в главнейших сражениях Отечественной войны: Смоленск, Шевардино, Бородино, Малоярославец, Красное. В 1813–1815 гг. полк был занят в зарубежных походах Русской армии, дошел до Парижа, отважно сражался в ключевых сражениях при Бауцене, Лейпциге, Париже." imgSrc="../../../../../assets/img/port/port_5.png" >
  	</app-block-left-side>
  </a>

  <!-- Блок Николая I Павловича(правосторонний блок) -->
  <a 
  (click)="navigationService.navigateTo('/epoch', {order:6}, true)"
  >
  	<app-block-right-side dateFrom="1825" dateTo="1855" title="Правление императора Николая I Павловича" description="Правление императора Николая I стало для славного боевого полка периодом мирной передышки. Полк занимался военной подготовкой, размещался в Москве, Санкт-Петербурге, Пскове. В печально знаменитой Крымской войне не участвовал." imgSrc="../../../../../assets/img/port/port_6.png" >
  	</app-block-right-side>
  </a>

  <!-- Блок Александра II Николаевича (левосторонний блок) -->
  <a 
  (click)="navigationService.navigateTo('/epoch', {order:7}, true)"
  >
  	<app-block-left-side dateFrom="1855" dateTo="1881" title="Правление императора Александра II Николаевича" description="В славную для России эпоху правления императора Александра II на долю Сибирского гренадерского полка выпала только одна война – Русско-турецкая 1877–1878 гг., в ходе которой он принял самое активное участие в ее главном эпизоде – осаде Плевны." imgSrc="../../../../../assets/img/port/port_7.png" >
  	</app-block-left-side>
  </a>

  <!-- Блок Николая II -->
  <a
  (click)="navigationService.navigateTo('/epoch', {order:8}, true)"
  >
  <div class="
      relative
      flex flex-col-reverse
      lg:flex-row
      items-center
      justify-center
      lg:items-start
      w-full
      pt-24
      md:pt-28
      lg:pt-40        
      group 
      cursor-pointer  
    ">

    <!-- Левая часть -->
    <div class="
        relative
        flex flex-col
        items-center
        lg:items-end
        space-y-2
        lg:pr-10
        w-full
        lg:w-1/2        
      ">

      <div class="
      flex flex-col
      items-center
      lg:items-end
      space-y-2 w-full">
        <h3 data-aos="fade-up" data-aos-offset="100"
          class="hidden lg:block ibm-plex-serif font-bold text-gold text-4xl">
          1881<br />1917
        </h3>
        <h3 data-aos="fade-up" data-aos-offset="100" class="lg:hidden ibm-plex-serif font-bold text-gold text-4xl">
          1881-1917
        </h3>

        <p data-aos="fade-up" data-aos-delay="50" data-aos-offset="100" class="
            ibm-plex-serif
            text-white text-center
            lg:text-right
            font-semibold
            text-lg
            w-1/2
            md:w-2/5
            lg:w-1/2
            2xl:w-2/6
          ">
          Правление императора Александра III Александровича / Правление
          императора Николая II Александровича
        </p>
        <p data-aos="fade-up" data-aos-delay="100" data-aos-offset="100" class="
            open-sans
            text-base text-white text-center
            lg:text-right
            text-opacity-70
            w-4/5
            px-2
            md:px-0 md:w-3/5
            lg:w-4/5
            xl:w-3/5
          ">
          В Первой мировой войне 9-й гренадерский Сибирский полк принимал участие в составе 3-й гренадерской дивизии. С первых дней войны полк действовал на Юго-Западном фронте против германских и австро-венгерских войск в Галиции и Польше, приняв участие в Галицийской битве (август – сентябрь 1914 г.), Варшавско-Ивангородской операции (сентябрь – октябрь 1914 г.), Горлицком прорыве (май 1915 г.), постепенно увязнув в тяжелых позиционных боях севернее Тернополя. 
        </p>
      </div>
    </div>

    <!-- Центральная часть -->
    <div class="
        hidden
        lg:flex
        absolute
        center-line
        flex-col
        items-center
        h-full
        z-20
      ">
      <div class="line bg-white h-40"></div>
      <div data-aos="fade-down" data-aos-offset="-280" class="center-dott bg-white rounded-full w-3 h-3 pt-2.5"></div>
    </div>

    <!-- Правая часть -->
    <div class="
        flex flex-row
        justify-center
        lg:justify-start
        w-full
        lg:w-1/2
        pb-6
        lg:pb-0
      ">
      <div class="relative lg:absolute bottom-0 z-10 ">
        <img data-aos="fade-up" data-aos-offset="100" class="h-64 md:h-72 lg:h-80 select-none"
          src="../../../../../assets/img/port/port_8.png" />
      </div>
    </div>
  </div>
  </a>
</div>

<!-- Промежуточный блок -->
<div class="h-10 w-full bg-footer"></div>

<!-- Футер -->
<app-footer [makePaddingTop]="false"></app-footer>