import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BlockLeftSideComponent } from './components/block-left-side/block-left-side.component';
import { BlockRightSideComponent } from './components/block-right-side/block-right-side.component';

@NgModule({
  declarations: [HomePageComponent, BlockLeftSideComponent, BlockRightSideComponent],
  imports: [CommonModule, SharedModule],
})
export class HomeModule {}
