import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { ExternalRoutes } from 'src/app/routes/external.routes';
import { EpochApiService } from '../../pages/epoch/services/epoch-api.service';

import { Store, select } from '@ngrx/store';
import { IState } from 'src/app/store';
import { selectAllEpochs } from 'src/app/store/selectors/epoch.selectors';
import { IEpoch } from 'src/app/store/actions/epoch.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() makePaddingTop = true;
  public data: any;
  public epochs: IEpoch[] = [];
  public currentYear = new Date().getFullYear();
  public v = '';

  private selectEpochs$ = this._store.pipe(select(selectAllEpochs))
  private subscription?: Subscription;

  constructor(
    public epochApiService: EpochApiService,
    public navigationService: NavigationService,
    public externalRoutes: ExternalRoutes,
    private _store: Store<IState>,
  ) {
    this.subscription = this.selectEpochs$.subscribe((val) => this.epochs = val)
  }

  ngOnInit(): void {
    // Получение списка эпох
    // this.epochApiService.getEpochsData();
  }

  ngOnDestroy() :void {
    this.subscription?.unsubscribe();
  }
}
