import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  about,
  acquire,
  contact,
  end_of_activity,
  epoch,
  foundation,
  heritage_archive,
  heritage_archive_card,
  military_glory,
  partners,
} from './routes/external.routes';
import { AuthorPageComponent } from './views/pages/about/author-page/author-page.component';
import { ContactPageComponent } from './views/pages/contact/contact-page/contact-page.component';
import { EpochPageComponent } from './views/pages/epoch/epoch-page/epoch-page.component';
import { CardPageComponent } from './views/pages/heritage-archive-card/card-page/card-page.component';
import { HeritageArchivePageComponent } from './views/pages/heritage-archive/heritage-archive-page/heritage-archive-page.component';
import { HomePageComponent } from './views/pages/home/home-page/home-page.component';
import { EndOfActivityPageComponent } from './views/pages/info-pages/end-of-activity-page/end-of-activity-page.component';
import { FoundationPageComponent } from './views/pages/info-pages/foundation-page/foundation-page.component';
import { MilitaryGloryPageComponent } from './views/pages/info-pages/military-glory-page/military-glory-page.component';
import { NotFoundPageComponent } from './views/pages/not-found/not-found-page/not-found-page.component';
import { PartnersPageComponent } from './views/pages/partners/partners-page/partners-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, data: { animation: 'home', title: 'СибПолк' } },

  { path: epoch, component: EpochPageComponent, data: { animation: epoch, title: 'СибПолк' } },
  {
    path: contact,
    component: ContactPageComponent,
    data: {
      animation: contact,
      title: 'Контакты',
    },
  },
  {
    path: heritage_archive,
    component: HeritageArchivePageComponent,
    data: {
      animation: heritage_archive,
      title: 'Архив наследия',
    },
  },
  {
    path: heritage_archive_card,
    component: CardPageComponent,
    data: {
      animation: heritage_archive,
    },
  },

  {
    path: partners,
    component: PartnersPageComponent,
    data: {
      animation: partners,
      title: 'Пратнеры и те кто о нас знает',
    },
  },

  // Информационные страницы
  {
    path: foundation,
    component: FoundationPageComponent,
    data: {
      animation: foundation,
      title: 'Рождение полка',
    },
  },

  {
    path: military_glory,
    component: MilitaryGloryPageComponent,
    data: {
      animation: military_glory,
      title: 'Боевая слава',
    },
  },

  {
    path: end_of_activity,
    component: EndOfActivityPageComponent,
    data: {
      animation: end_of_activity,
      title: 'Окончание деятельности',
    },
  },

  {
    path: about,
    component: AuthorPageComponent,
    data: {
      animation: about,
      title: 'О книге',
    },
  },

  {
    path: '**',
    component: NotFoundPageComponent,
    data: { animation: '**', title: 'Страница не найдена' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
