import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { progressSelector } from 'src/app/store/selectors/epoch.selectors';
import { IState } from 'src/app/store';
import { VideoIntroService } from '../services/intro-video.service';
import { HttpEvent, HttpEventType} from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';
const { epochsDataSizeExpected, videoSizeDataExpected, skipPreload } = environment;

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  public preloaderValue = 0;
  public preloaderStatus = 'show';
  // public preloaderStatus = 'hide';
  public isPreloaderShow = true;
  public preloadedVideo: any = '';
  public fakeVideo: any;

  private totalDataSizeExpected = epochsDataSizeExpected + videoSizeDataExpected;
  private epochsDataReceived = 0;
  private videoDataReceived = 0;

  private progress$ = forkJoin(
    [this._store.pipe(
      select(progressSelector),
      map((val) => {
        this.epochsDataReceived = val.chunk;
        this.handleNextDataReceived();
        if ((this.videoDataReceived + this.epochsDataReceived) >= this.totalDataSizeExpected) {
          this.finishPrelod();
        }
        return val;
      })
    ),
    this.videoService.getVideo().pipe(map((res: HttpEvent<HttpEventType>) => {
      if (res.type === HttpEventType.DownloadProgress) {
        this.videoDataReceived = res.loaded;
        this.handleNextDataReceived();
        if ((this.videoDataReceived + this.epochsDataReceived) >= this.totalDataSizeExpected) {
          this.finishPrelod();
        }
      }
      if (res.type === HttpEventType.Response) {
        this.preloadedVideo = res.body
        this.fakeVideo = this.sanitazer.bypassSecurityTrustUrl(URL.createObjectURL(this.preloadedVideo));
      }
      return res
    }))]
  ).subscribe((res) => {
    console.log('r', res)
  })


  constructor( private _store: Store<IState>, private videoService: VideoIntroService, private sanitazer: DomSanitizer) {
    this.finishPrelod = this.finishPrelod.bind(this);
    this.handleNextDataReceived = this.handleNextDataReceived.bind(this);
    if (skipPreload) {
      setTimeout(() => this.finishPrelod(), 10000);
    }
  }

  public unsubscribe() {
    this.progress$.unsubscribe();
    URL.revokeObjectURL(this.fakeVideo);
  }

  private finishPrelod() {
    this.preloaderValue = 100;
    // this.preloaderStatus = 'hide';
    document.documentElement.style.setProperty('--curtain-transform', '-100%');
    setTimeout(() => {
      this.preloaderStatus = 'hide';
      this.isPreloaderShow = false;
      document.body.classList.remove('overflow-hidden');
    }, 500);
    // setTimeout(() => (this.isPreloaderShow = false), 50);
  }

  private handleNextDataReceived() {
    this.preloaderValue = Math.round(100 * (this.epochsDataReceived + this.videoDataReceived) / this.totalDataSizeExpected);
    document.documentElement.style.setProperty('--curtain-transform', `-${this.preloaderValue}%`);
  }

}
