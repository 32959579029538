import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'romanize',
})
export class RomanizePipe implements PipeTransform {
  private arabic = [
    1,
    4,
    5,
    9,
    10,
    40,
    50,
    90,
    100,
    400,
    500,
    900,
    1000,
    4000,
    5000,
    9000,
    10000,
  ];

  private roman = [
    'I',
    'IV',
    'V',
    'IX',
    'X',
    'XL',
    'L',
    'XC',
    'C',
    'CD',
    'D',
    'CM',
    'M',
    'M&#8577;',
    '&#8577;',
    '&#8577;&#8578;',
    '&#8578;',
  ];

  transform(num: number | string): string {
    // Преобразовываю полученное значение в число
    // если оно таковым не является
    if (typeof num !== 'number') {
      num = Number(num);
    }

    let rezult = '';
    let n = this.arabic.length - 1;

    while (num > 0) {
      if (num >= this.arabic[n]) {
        rezult += this.roman[n];
        num -= this.arabic[n];
      } else {
        n--;
      }
    }

    return rezult;
  }
}
