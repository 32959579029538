import { Action } from "@ngrx/store";
import { IEpoch } from "./epoch.actions";

export interface IRoutes {};

export enum EMapRoutesActions {
  LoadMapRoutes = '[MapRoutes] Loading mapRoutes',
  LoadMapRoutesSuccess = '[MapRoutes] Load Sucesss',
  LoadMapRoutesError = '[MapRoutes] Load Error',
  LoadMapRoutesAsync = '[MapRoutes] async',
  LoadMapRoutesProgress = '[MapRoutes] Progress'
}

export class LoadMapRoutes implements Action {
  readonly type = EMapRoutesActions.LoadMapRoutes;

  constructor (public args: IEpoch[]) {}
}

export class LoadMapRoutesSuccess implements Action {
  readonly type = EMapRoutesActions.LoadMapRoutesSuccess;

  constructor (public payload: { routes: IRoutes[] }) {}
}

export class LoadMapRoutesError implements Action {
  readonly type = EMapRoutesActions.LoadMapRoutesError;

  constructor (public payload: { error: Error }) {}
}

export class LoadMapRoutesAsync implements Action {
  readonly type = EMapRoutesActions.LoadMapRoutesAsync;
}

export class LoadEpochsProgress implements Action {
  readonly type = EMapRoutesActions.LoadMapRoutesProgress;

  constructor (public payload: { progress: number }) {}
}

export type MapRoutesActionsUnion = LoadMapRoutes | LoadMapRoutesSuccess | LoadMapRoutesError | LoadMapRoutesAsync | LoadEpochsProgress;
