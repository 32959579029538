import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormsService } from 'src/app/core/forms/forms.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
  @Input() form: any;
  @Input() fieldName = '';
  @Input() fieldLabel = '';

  constructor(public formsService: FormsService) {}

  ngOnInit(): void {}
}
