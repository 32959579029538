<!-- Заголовок страницы -->
<div class="grid grid-cols-1 place-items-center">
  <div class="flex flex-col items-center w-full">
    <img
      class="select-none"
      src="../../../../../assets/img/monogram-standart.svg"
    />
    <h3
      class="ibm-plex-serif font-bold text-black text-center text-4xl pt-2 pb-4"
    >
      {{ pageTitle }}
    </h3>
    <p
      class="open-sans text-black font-normal text-sm md:w-3/4 lg:w-2/5 leading-snug text-center"
    >
      {{ pageDescription }}
    </p>
    <img
      class="select-none"
      src="../../../../../assets/img/monogram-line.svg"
    />
  </div>
</div>
