import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/core/router-navigations/router.service';
import { IEpoch } from 'src/app/store/actions/epoch.actions';

@Component({
  selector: 'app-imperators-panel-mobile',
  templateUrl: './imperators-panel-mobile.component.html',
  styleUrls: ['./imperators-panel-mobile.component.css'],
})
export class ImperatorsPanelMobileComponent implements OnInit {
  public displayAllEpohs = false;
  // public activeEpoch = 0;
  @Input()
  public activeEpoch = 0;
  @Input()
  public epochs: IEpoch[] = [];

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.activeEpoch = Number(params.order);
    });
  }

  ngOnInit(): void {}
}
