import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { APIservice } from 'src/app/core/api/api.service';
import { ILetterFormModel } from '../classes/letter.class';

@Injectable()
export class LettersService {
  public letterForm: FormGroup;

  constructor(private fb: FormBuilder, private api: APIservice) {
    // Модель формы формления заявки
    this.letterForm = this.createForm({
      email: ['', [Validators.required]],
      phone: '',
      full_name: ['', [Validators.required]],
      comment: ['', [Validators.required]],
    });
  }

  /**
   * Получить значения формы через интерфейс формы
   */
  public get formValue(): ILetterFormModel {
    return this.letterForm.value as ILetterFormModel;
  }

  /**
   * Метод создающий формц фильтрации
   * @param model модель формы
   * @returns форму фильтрации
   */
  private createForm(model: ILetterFormModel): FormGroup {
    return this.fb.group(model);
  }

  /**
   * Метод отправки заявки
   */
  public letterFormOnSubmit(): void {
    if (this.letterForm.valid) {
      this.api.POST('/letters', this.formValue).subscribe((res) => {
        console.log(res);
      });
    } else {
      this.letterForm.markAllAsTouched();
    }
  }
}
