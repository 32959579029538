import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { IPerson } from '../../classes/filter.classes';

@Component({
  selector: 'app-archive-card',
  templateUrl: './archive-card.component.html',
})
export class ArchiveCardComponent implements OnInit {
  @Input() person: IPerson;
  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.person = {
      content: {
        biography: '',
        facts: '',
        images: [],
      },

      date_from: '',
      date_to: '',
      full_name: '',
      id: 0,
      rank_big: '',
      rank_little: '',
      world_war_one: '0',
    };
  }

  ngOnInit(): void {}

  goToPerson(personID: number): void {
    this.router.navigate(['card'], { queryParams: { id: personID } });
    this.navigationService.scrollToTop(3);
  }
}
