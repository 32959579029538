<div class="flex flex-row space-x-2 justify-center items-center w-full">
  <ng-container *ngFor="let link of links; index as i">
    <ng-container *ngIf="i !== 0 && i !== links.length - 1">
      <ng-container *ngIf="link['active']; else notActive">
        <button
          disabled
          class="bg-primary py-2.5 px-4 rounded-lg text-white text-sm font-semibold hover:opacity-60 transition delay-300 duration-300 ease-in-out"
        >
          {{ link["label"] }}
        </button>
      </ng-container>

      <ng-template #notActive>
        <button
          (click)="
            heritageArchiveApiService.getHeritageArchiveData(
              '&page=' +
                i +
                heritageArchiveApiService.activeFilter.substr(1),
              true
            )
          "
          class="py-2.5 px-4 rounded-lg text-black text-sm font-semibold hover:opacity-60 transition delay-300 duration-300 ease-in-out"
        >
          {{ link["label"] }}
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
