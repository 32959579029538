import { Component, EventEmitter, OnInit } from '@angular/core';
import { LettersService } from '../../services/letters.service';

@Component({
  selector: 'app-letter-form',
  templateUrl: './letter-form.component.html',
})
export class LetterFormComponent implements OnInit {
  public activeFieldID = '';
  public activeFieldAction = '';

  constructor(public lettersService: LettersService) {}

  ngOnInit(): void {}

  public setActiveFiealStyle(event: any): void {
    this.activeFieldAction = event.type;
    this.activeFieldID = event.srcElement.id;
  }
}
