import { Injectable } from '@angular/core';
import { IEpoch } from './epoch-api.service';

var pTo = 0

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public epoch: IEpoch | any = 0;
  constructor() {}

  public setEpochData(epochValue: IEpoch): void {
    this.epoch = epochValue;
  }

  public getActiveContentBlock(): number | string {
    return (window as any).animationStepper;
  }

  public toggleEpochContent(): void {
    const map = document.getElementById('map_block');
    const content = document.getElementById('content_block');

    if (!map?.classList.toggle('invisible')) {     
      pTo =  window.pageYOffset
    } 

    if (!content?.classList.toggle('hidden')) {
      window.scrollTo(0, pTo);
    }      
  }
}
