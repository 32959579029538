import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

interface IEpochContent {
  description: string;
  event_points: any;
  route: any;
  menu_name: string;
  mini_image: string;
  full_image: string;
  route_file: string;
}

export interface IEpoch {
  content: IEpochContent;
  events: Array<any>;
  name: string;
  order: number;
  persons: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class EpochMapService {

  constructor(private http: HttpClient) {}

  public getEpochRoute(filePath: string) {
    return this.http.get(filePath)   
  }

  public getAllEpochRoutes(path: IEpoch[]){
    const requests = path.map((epoch) => this.http.get(epoch.content.route_file));
    return forkJoin(requests);
  }
}
