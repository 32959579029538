import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';

@Component({
  selector: 'app-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
})
export class BottomNavigationComponent implements OnInit {
  @Input() nextLink = '';
  @Input() nextTitle = '';

  @Input() prevLink = '';
  @Input() prevTitle = '';

  constructor(public navigationService: NavigationService) {}

  ngOnInit(): void {}
}
