<div class="grid grid-cols-1 lg:grid-cols-2">
  <app-decor-page-container contentSide="left">
    <div
      class="
        grid grid-cols-1
        md:gap-12
        lg:gap-10
        xl:gap-12
        place-items-center
        2xl:w-full
      "
    >
      <div class="grid grid-cols-1 place-items-center">
        <!-- Заголовок страницы -->
        <div class="flex flex-col items-center w-full 2xl:w-full">
          <img
            class="select-none h-7 2xl:h-8"
            src="../../../../../assets/img/monogram-standart.svg"
          />
          <h3
            class="
              ibm-plex-serif
              font-bold
              text-black text-center text-4xl
              pt-2
              pb-4
            "
          >
            {{ pageTitle }}
          </h3>

          <p
            class="
              open-sans
              text-black
              font-normal
              text-sm
              w-4/5
              leading-snug
              text-center
            "
          >
            <ng-container *ngIf="pageTitle === 'Купить книгу'; else contact">
              Оставьте заявку на покупку Книги, заполнив форму обратной связи
              ниже. В ближайшее время с вами свяжется наш менеджер.
            </ng-container>

            <ng-template #contact>
              По любым вопросам связанным с нашим проектам просим обращаться
              через форму обратной связи, расположенной ниже. Будем рады
              получить от вас материал не представленный на нашем ресурсе,
              связанный с 9-м Сибирским гренадерским полком.
            </ng-template>
          </p>
          <!-- <img
            class="select-none"
            src="../../../../../assets/img/monogram-line.svg"
          /> -->
        </div>
      </div>

      <!-- Form -->
      <app-letter-form
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
      ></app-letter-form>
    </div>
  </app-decor-page-container>

  <div class="hidden lg:block w-full h-full select-none opacity-60">
    <div
      class="h-full 2xl:h-screen w-full bg-no-repeat bg-cover bg-center"
      [ngStyle]="{
        'background-image': 'url(../../../../../assets/img/grenadiers.png)'
      }"
    ></div>
  </div>
</div>
