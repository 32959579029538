<div
  [@preoloader]="preloaderService.preloaderStatus"
  class="fixed h-screen w-screen top-0 bg-footer z-50"
>
  <!-- Основной 100% -->
  <div class="flex flex-col items-center justify-center h-full w-full">
    <img
      class="select-none h-52 2xl:h-60"
      src="../../../../../assets/img/logo_white.png"
    />
  </div>

  <!-- Шторка -->
  <div
    id="curtain"
    class="absolute-center w-full h-56 2xl:h-64 bg-footer preload-progress"
  ></div>
  <!-- <div
    id="curtain"
    class="absolute-center w-full h-56 2xl:h-64 bg-footer slideOutUp"
  ></div> -->

  <!-- Загрузка 20% -->
  <div class="h-full w-full">
    <img
      class="absolute-center select-none h-52 2xl:h-60 opacity-20"
      src="../../../../../assets/img/logo_white.png"
    />
  </div>

  <div
    class="absolute bottom-0 flex flex-row justify-center w-full mb-20 lg:mb-10 select-none"
  >
    <p class="ibm-plex-serif text-gold font-bold text-3xl z-10">
      {{ preloaderService.preloaderValue }}%
    </p>
  </div>
</div>
