import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/**
 * Анимация появления контента после загрузчика
 */
export const preoladerAnimation = [
  trigger('preoloader', [
    state('show', style({ opacity: 1 })),
    state('hide', style({ opacity: 0 })),

    transition('show => hide', animate('0.3s')),
  ]),
];
