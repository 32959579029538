import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { RouterService } from 'src/app/core/router-navigations/router.service';
import { ExternalRoutes } from 'src/app/routes/external.routes';

@Component({
  selector: 'app-author-page',
  templateUrl: './author-page.component.html',
})
export class AuthorPageComponent implements OnInit {
  constructor(
    private router: Router,
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    public externalRoutes: ExternalRoutes
  ) {
    this.routerService.setPageTitle(this.activatedRoute);
  }

  ngOnInit(): void {}

  public buyBookNavigate(): void {
    this.router.navigate(['contact'], { queryParams: { type: 'buy_book' } });
  }
}
