<ng-container *ngIf="preloaderService.isPreloaderShow">
  <preloader></preloader>
</ng-container>

<app-combat-form-modal
  #combatForm
  [order] = "order"
  [epoch] = "epochData?.name"
  [events] = "epochData?.events"
  [isOpen] = "isModalOpen"
  (onClose) = "closeModal()"
  [onSelection] = "onSelection"
>
</app-combat-form-modal>

<!-- <app-combat-form-modal></app-combat-form-modal> -->

<!-- Компонент просмотра картинки из статьи с зумом -->
<app-image-panzoom [image]="imagePreview" (closePreview)="setImagePreview('')" (contextmenu)="onContextMenu($event)"></app-image-panzoom>

<div class="grid grid-cols-1 xl:grid-cols-12">
  <!-- Блок с картой -->
  <div
    id="map_block"
    class="invisible xl:visible relative place-content-end xl:block xl:col-span-7 h-0 xl:h-auto"
  >
    <app-video-control></app-video-control>
    <app-map></app-map>
  </div>

  <!-- Блок контента -->
  <div id="content_block" class="w-full xl:col-span-5 shadow-xl" (contextmenu)="onContextMenu($event)">
    <!-- Панель выбора эпохи -->
    <div class="invisible xl:visible">
      <app-imperators-panel [epochs]="epochsList" [activeEpoch]="order"></app-imperators-panel>
    </div>

    <!-- Иконка карты -->
    <div
      class="
        xl:hidden
        fixed
        left-0
        bottom-0
        pb-5
        md:py-4
        px-4
        md:px-6
        lg:py-5
        xl:py-3
        z-10
      "
    >
      <btn-fill-elastic (click)="contentService.toggleEpochContent()"
        >Открыть карту</btn-fill-elastic
      >
    </div>

    <div
      class="
        fixed
        right-0
        bottom-0
        pb-5
        md:py-4
        px-4
        md:px-6
        lg:py-5
        xl:py-3
        z-10
      "
    >
      <div class="flex flex-col space-y-3">
        <!--Мобильная Панель выбора эпохи -->
        <div class="xl:hidden">
          <app-imperators-panel-mobile 
            [epochs]="epochsList"
            [activeEpoch]="order">
          </app-imperators-panel-mobile>
        </div>

        <!-- Иконка боевого формуляра -->
        <button data-target="combat-form-modal" class="modal-trigger" (click)="openModal()" >
          <btn-fill-round>
            <img
              class="select-none h-5 w-5"
              src="../../../../../assets/icons/cannon.svg"
            />
          </btn-fill-round>
        </button>
      </div>
    </div>

    <!-- Контент выбранной эпохи -->

    <div
      class="
        relative
        flex flex-col
        justify-center
        items-center
        h-full
        mx-9
        md:mx-24
        xl:mx-14
        2xl:mx-24
      "
      (selectstart)="onSelection($event)"
      style="user-select: none;"
    >
      <div
        class="
          relative
          grid grid-cols-1
          place-items-center
          mt-10
          md:mt-16
          lg:mt-20
          xl:mt-24
          2xl:mt-28
          z-0
        "
      >
        <div
          [id]="contentService.epoch.order + '_0'"
          class="grid grid-cols-1 place-items-center"
        >
          <ng-container *ngIf="contentService.epoch !== 0">
            <img
              class="select-none h-56 md:h-64 2xl:h-80"
              [src]="contentService.epoch.content.full_image"
            />
          </ng-container>

          <div class="flex flex-col items-center w-full mt-8">
            <p class="open-sans text-brown font-semibold text-sm">Эпоха</p>
            <h3
              class="
                ibm-plex-serif
                font-bold
                text-black text-center text-2xl
                2xl:text-4xl
                pt-1.5
                pb-2
              "
            >
              {{ contentService.epoch.name }}
            </h3>
            <img
              class="select-none transform rotate-180 h-7"
              src="../../../../../assets/img/monogram-standart.svg"
            />
            <div class="event mt-5 lg:w-4/5 xl:w-full">
              <div
                class="
                  open-sans
                  text-black
                  font-normal
                  text-sm
                  leading-snug
                  text-center
                "
              >
                <ng-container *ngIf="contentService.epoch !== 0">
                  <div
                    class="2xl:text-base"
                    [innerHTML]="contentService.epoch.content.description"
                  ></div>
                </ng-container>
              </div>
            </div>
            <img
              class="select-none"
              src="../../../../../assets/img/monogram-line.svg"
            />
          </div>
        </div>

        <div class="grid grid-cols-1 gap-y-10 w-full my-20">
          <app-persons></app-persons>

          <app-legend order="{{order}}"></app-legend>
          <ng-container
            *ngFor="let event of contentService.epoch.events; let i = index"
          >
            <app-content
              [id]="event.identrifier"
              [action]="i + 1"
              [title]="event.content.name"
              [date]="event.date"
              [description]="event.content.description"
              [audio]="event.content.audio_file"
              (newImagePreview)="setImagePreview($event)"
            ></app-content>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
