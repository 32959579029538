import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';
import { AuthorPageComponent } from './author-page/author-page.component';

@NgModule({
  declarations: [AuthorPageComponent],
  imports: [CommonModule, TemplatesModule, SharedModule],
})
export class AboutModule {}
