import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BtnEffectsService {
  constructor() {}

  /**
   * Метод для анимации стрелочки
   * @param action текущее событие
   */
  public moveArrow(action: string): void {
    console.log(action);

    const elem = document.getElementById('next');
    if (elem !== null && action === 'mouseover') {
      elem.classList.add('arrow-move-right');
    } else if (elem !== null && action === 'mouseleave') {
      elem.classList.add('arrow-move-left');
      setTimeout(
        () => elem.classList.remove('arrow-move-right', 'arrow-move-left'),
        300
      );
    }
  }
}
