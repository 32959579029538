import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { switchMap, map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { IState } from '../index';
import { LoadEpochs, LoadEpochsSuccess, LoadEpochsError, LoadEpochsProgress, EpochActions, LoadEpochsAsync } from '../actions/epoch.actions';
import { EpochDataService } from '../../core/services/epochs-data.service';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { selectAllEpochs } from '../selectors/epoch.selectors'

@Injectable()
export class EpochsEffects {
  getEpochs$ = createEffect(() => {
    return this._actions$.pipe(
      ofType<LoadEpochs>(EpochActions.LoadEpochs),
      mergeMap(() => this._epochsService.getEpochs().pipe(
        map(
          (event: HttpEvent<any>) => {
           if (event.type === HttpEventType.Response) {
            if (event.status >= 400) {
              throwError('load epochs error');
            }
            return new LoadEpochsSuccess( { epochs: event.body.data });
           } else if (event.type === HttpEventType.DownloadProgress) {
            if (!event.total) {
              event.total = event.total = 4739563.52;
            }
            const percentDone = Math.round(100 * event.loaded / event.total);
            return new LoadEpochsProgress({ progress: percentDone, chunk: event.loaded });
           }
           return new LoadEpochsAsync()
          }
        ),
        catchError(( error ) => {
          console.log('effect getEpochs error', error)
          return of(new LoadEpochsError({ error: new Error('Ошибка зазрузки эпох')}))
        })
      ))
    )
  });

  constructor (private _epochsService: EpochDataService, private _actions$: Actions, private _store: Store<IState>) {}
}
