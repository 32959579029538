<div class="flex flex-row w-full items-start">
  <!-- Картинка -->
  <div
    class="
      fixed
      img-container
      hidden
      lg:block
      h-full
      animate__animated animate__fadeInLeft animate__fast
    "
  >
    <img
      class="h-screen w-full select-none"
      src="../../../../../assets/img/foundation_img.png"
    />

    <div
      class="absolute right-0 bottom-0 bg-black bg-opacity-70 py-4 px-6 mb-14"
    >
      <div class="flex flex-col space-y-0.5 mt-2">
        <p class="open-sans text-sm text-light-gray opacity-90">
          Фрагмент указа Царя и Великого князя Петра Алексеевича <br />
          о наборе даточных людей и денег на них, данный 17 ноября 1699 г.
          (РГВИА)
        </p>
      </div>
    </div>
  </div>

  <!-- Контент -->
  <div
    class="
      absolute
      right-0
      text-container
      mt-12
      md:mt-14
      lg:mt-9
      animate__animated animate__fadeInRight animate__fast
    "
  >
    <!-- Заголовок -->
    <app-head [pageTitle]="routerService.pageTitle"> </app-head>

    <!-- Текст -->
    <div class="mx-10 md:mx-28 lg:mx-24 2xl:mx-32 mt-10 lg:mt-12 2xl:mt-14">
      <p class="ibm-plex-serif text-black text-base select-none">
        9-й гренадерский Сибирский полк — старейший, «петровский» полк русской
        армии — сформирован на Генеральном дворе села Преображенского в 1699
        году под именем своего первого командира полковника Ирика (Эриха)
        Григорьевича фон Вердена — бывшего полковника Козловского солдатского
        полка Белогородского разряда, участника Азовских походов. Первое
        название полка: «Ирика фон Вердена пехотный полк». Днем сформирования
        полка всегда считалось 25 июня 1700 года. <br />
        <br />
        В хронике полка, помещенной в справочной книжке Императорской Главной
        квартиры, сказано, что «1700 года, июня 25 дня, в Москве, генералом
        Вейде, из рекрут сформирован пехотный Ирика фон Вердена полк, в составе
        1 гренадерской и 10 фузилерных рот». Днем же рождения полка следует
        считать 17 ноября 1699 года, когда вышел указ Петра I о наборе с
        духовных лиц и светских помещиков даточных людей для формирования полков
        нового строя.
      </p>
    </div>

    <app-bottom-navigation
      [nextLink]="externalRoutes.military_glory"
      nextTitle="Боевая слава"
    >
    </app-bottom-navigation>
  </div>
</div>
