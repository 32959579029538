import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-decor-page-container',
  templateUrl: './decor-page-container.component.html',
})
export class DecorPageContainerComponent implements OnInit {
  @Input() contentSide: 'left' | 'right' = 'right';
  constructor() {}

  ngOnInit(): void {}
}
