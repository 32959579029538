import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from 'src/app/core/router-navigations/router.service';

@Component({
  selector: 'app-partners-page',
  templateUrl: './partners-page.component.html',
})
export class PartnersPageComponent implements OnInit {
  constructor(
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute
  ) {
    this.routerService.setPageTitle(this.activatedRoute);
  }

  ngOnInit(): void {}
}
