import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
// @ts-ignore
import { Panzoom } from '@fancyapps/ui';

import "@fancyapps/ui/dist/panzoom.css";

@Component({
  selector: 'app-image-panzoom',
  templateUrl: './image-panzoom.component.html',
  styleUrls: ['./image-panzoom.component.css']
})
export class ImagePanzoomComponent implements OnInit {
  @Input() image = '';
  @Output() closePreview = new EventEmitter<''>();
  public className = this.image ? 'image-preview fixed top-0 left-0 z-30' : 'hidden';
  public Panzoom = {
    zoomIn: () => {},
    zoomOut: () => {},
    destroy: () => {},
  };

  constructor() { }

  ngOnInit(): void {
    this.Panzoom = new Panzoom(document.querySelector("#panzoom"), {
      zoomFriction: 0.8,
      maxScale: () => 10,
      step: 0.5,
    });
  }

  ngOnChanges() {
    this.className = this.image ? 'image-preview fixed top-0 left-0 z-30' : 'hidden';
    if (this.image) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  ngOnDestroy() {
    this.Panzoom.destroy();
    document.body.classList.remove('overflow-hidden');
  }

  public close() {
    this.closePreview.emit('');
  }

  public scrollControl(ev: Event) {
    if (this.image) {
      ev.preventDefault();
    }
  }

  public zoomIn(ev: Event) {
    ev.stopPropagation();
    this.Panzoom.zoomIn();
  }

  public zoomOut(ev: Event) {
    ev.stopPropagation();
    this.Panzoom.zoomOut();
  }

}
