<!-- правосторонний блок -->
<!-- <a [href]="link"> -->
<div class="
    relative
    flex flex-col-reverse
    lg:flex-row
    items-center
    justify-center
    lg:items-start
    w-full
    pt-24
    md:pt-24
    lg:pt-40
    group
    cursor-pointer  
  ">

  <!-- Левая часть -->
  <div class="
      relative
      lg:pr-10
      w-full
      lg:w-1/2
    ">

    <ng-container *ngIf="hidd">
      <div id="placeholder"
        class="absolute right-0 text-right flex flex-col xl:pr-10 gap-4 opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition duration-500 ease-in-out h-full justify-center w-full xl:w-auto items-center xl:items-end">
        <h3 data-aos="fade-up" data-aos-offset="100"
          class="ibm-plex-serif font-bold text-gold text-4xl text-center xl:text-right">
          Раздел <br> наполняется
        </h3>
        <p class="ibm-plex-serif font-bold text-right text-white text-xl">Релиз ({{finalDate}})</p>
      </div>
    </ng-container>

    <div [ngClass]="{'opacity-50 group-hover:opacity-10 group-focus:opacity-10': hidd}" class="
      flex flex-col
      items-center
      lg:items-end
      space-y-2 
      w-full 
      transition duration-500 ease-in-out">
      <h3 data-aos="fade-up" data-aos-offset="100" [ngClass]="{'text-gold': !hidd, 'text-white': hidd}"
        class="ibm-plex-serif font-bold text-gold text-4xl">
        {{ dateFrom }}<br />{{ dateTo }}
      </h3> 

      <p data-aos="fade-up" data-aos-delay="50" data-aos-offset="100" class="
        ibm-plex-serif
        text-white text-center
        lg:text-right
        font-semibold
        text-lg
        w-3/4
        md:w-2/5
        lg:w-1/2
        2xl:w-2/6
      ">
        {{ title }}
      </p>
      <p data-aos="fade-up" data-aos-delay="100" data-aos-offset="100" class="
        open-sans
        text-base text-white text-center
        lg:text-right
        text-opacity-70
        w-4/5
        px-2
        md:px-0
        md:w-3/5
        lg:w-4/5
        xl:w-3/5
      ">
        {{ description }}
      </p>
    </div>

  </div>

  <!-- Центральная часть -->
  <div class="
      hidden
      lg:flex
      absolute
      center-line
      flex-col
      items-center
      h-full
      z-20
    ">
    <div class="padding-line line bg-white h-40"></div>
    <div data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-offset="-180"
      class="center-dott bg-white rounded-full w-3 h-3 pt-2.5"></div>
    <div class="line bg-white h-full"></div>
  </div>

  <!-- Правая часть -->
  <div class="
      flex flex-row
      justify-center
      lg:justify-start
      w-full
      lg:w-1/2
      pb-6
      lg:pb-0
    ">
    <div [ngClass]="{'opacity-40': hidd}" class="relative lg:absolute bottom-0 z-10">
      <img data-aos="fade-up" data-aos-offset="100" [ngClass]="{'filter grayscale': hidd}"
        class="h-64 md:h-72 lg:h-80 select-none" [src]="imgSrc" />
    </div>
  </div>
</div>
<!-- </a> -->