import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'btn-fill-round',
  templateUrl: './btn-fill-round.component.html',
})
export class BtnFillRoundComponent implements OnInit {
  @Input() type = 'button';

  constructor() {}

  ngOnInit(): void {}
}
