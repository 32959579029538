import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-right-side',
  templateUrl: './block-right-side.component.html',
})
export class BlockRightSideComponent implements OnInit {
  @Input() dateFrom = '';
  @Input() dateTo = '';

  @Input() title = '';
  @Input() description = '';

  @Input() imgSrc = '';
  @Input() link = '#';

  @Input() finalDate = '';
  @Input() hidd = false;

  constructor() {}

  ngOnInit(): void {}
}
