import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BtnEffectsService } from 'src/app/core/effects/btn-effects.service';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { RouterService } from 'src/app/core/router-navigations/router.service';
import { ExternalRoutes } from 'src/app/routes/external.routes';

@Component({
  selector: 'app-end-of-activity-page',
  templateUrl: './end-of-activity-page.component.html',
})
export class EndOfActivityPageComponent implements OnInit {
  constructor(
    public routerService: RouterService,
    public activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    public externalRoutes: ExternalRoutes
  ) {
    this.routerService.setPageTitle(this.activatedRoute);
  }

  ngOnInit(): void {}
}
