import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';

@NgModule({
  declarations: [NotFoundPageComponent],
  imports: [CommonModule, TemplatesModule, SharedModule],
})
export class NotFoundModule {}
