<div
  [id]="id"

  class="
    event
    grid grid-cols-1
    w-full
    border-t border-dashed border-gray-300
    pt-8
  "
>
  <!-- Заголовок главы -->
  <div class="flex flex-col items-center space-y-2.5 w-full">
    <!-- Номер главы истории -->
    <div class="flex flex-row items-center space-x-2.5 select-none">
      <p class="ibm-plex-serif font-normal text-2xl text-brown">—</p>
      <p class="ibm-plex-serif font-medium text-2xl text-brown">
        {{ action | romanize }}
      </p>
      <p class="ibm-plex-serif font-normal text-2xl text-brown">—</p>
    </div>

    <!-- Текст заголовка -->
    <div class="flex flex-row justify-center w-full">
      <h5
        class="
          ibm-plex-serif
          font-bold
          text-xl 2xl:text-2xl text-black text-center
          uppercase
        "
      >
        {{ title }}
      </h5>
    </div>

    <!-- Текст заголовка -->
    <p class="text-base text-brown font-bold opacity-50 uppercase">
      {{ date }}
    </p>
  </div>

  <app-audio-control *ngIf="audio" [audio]="audio"></app-audio-control>

  <!-- Описание главы -->
  <div class="text-sm 2xl:text-base w-full text-black mt-6">
    <div [innerHTML]="description" (click)="onClick($event)"></div>
  </div>
</div>
