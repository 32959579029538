import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { TemplatesModule } from '../../templates/templates.module';
import { FoundationPageComponent } from './foundation-page/foundation-page.component';
import { MilitaryGloryPageComponent } from './military-glory-page/military-glory-page.component';
import { EndOfActivityPageComponent } from './end-of-activity-page/end-of-activity-page.component';
import { BottomNavigationComponent } from './components/bottom-navigation/bottom-navigation.component';
import { HeadComponent } from './components/head/head.component';

@NgModule({
  declarations: [
    FoundationPageComponent,
    MilitaryGloryPageComponent,
    EndOfActivityPageComponent,
    BottomNavigationComponent,
    HeadComponent,
  ],
  imports: [CommonModule, TemplatesModule, SharedModule],
  exports: [],
})
export class InfoPagesModule {}
