import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-audio-control',
  templateUrl: './audio-control.component.html',
  styleUrls: ['./audio-control.component.css']
})
export class AudioControlComponent implements OnInit {
  @Input() audio = '';
  currentTime = 0;
  private timeStep = 5;

  constructor() { }

  ngOnInit(): void {}

  seekForward() {
    this.currentTime += this.timeStep;
  }

  seekBackward() {
     if (this.currentTime > this.timeStep) {
       this.currentTime -= this.timeStep;
     }
  }

  onTimeUpdate(event: CustomEvent<number>) {
    this.currentTime = event.detail;
  }

  customResolver(iconName: string) {
    return `/assets/icons/${iconName}.svg`;
  }

}