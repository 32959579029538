import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HeritageArchiveModule } from './views/pages/heritage-archive/heritage-archive.module';
import { ComponentsModule } from './views/components/components.module';
import { ContactModule } from './views/pages/contact/contact.module';
import { HeritageArchiveCardModule } from './views/pages/heritage-archive-card/heritage-archive-card.module';
import { EpochModule } from './views/pages/epoch/epoch.module';
import { NotFoundModule } from './views/pages/not-found/not-found.module';
import { HomeModule } from './views/pages/home/home.module';
import { PartnersModule } from './views/pages/partners/partners.module';
import { InfoPagesModule } from './views/pages/info-pages/info-pages.module';
import { AboutModule } from './views/pages/about/about.module';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import { reducers } from './store';
import { EpochsEffects } from './store/effects/epochs.effects';
import { MapEffects } from './store/effects/map-routes.effects';

registerLocaleData(localeRu);

@NgModule({
  providers: [{ provide: LOCALE_ID, useValue: 'ru' }],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeritageArchiveModule,
    HeritageArchiveCardModule,
    ContactModule,
    ComponentsModule,
    NotFoundModule,
    HomeModule,
    PartnersModule,
    EpochModule,
    InfoPagesModule,
    AboutModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([EpochsEffects, MapEffects])
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
