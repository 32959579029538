import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
})
export class HeadComponent implements OnInit {
  @Input() pageTitle = '';

  constructor() {}

  ngOnInit(): void {}
}
