import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  public activeFieldID = '';
  public activeFieldAction = '';

  constructor() {}

  /**
   * Метод для записи текущего фокусированного поля
   * @param event событие клика на input
   */
  public setActiveFiealStyle(event: any): void {
    this.activeFieldAction = event.type;
    this.activeFieldID = event.srcElement.id;
  }
}
