<footer
  [ngClass]="{
    'mt-20 lg:mt-28': makePaddingTop
  }"
  class="bg-footer w-full"
>
  <app-page-container [mt]="makePaddingTop">
    <div class="grid grid-cols-1 gap-y-16 py-10 md:py-12">
      <!-- Верхняя часть футтера -->
      <div
        class="
          flex flex-col-reverse
          md:flex-row md:space-x-10
          lg:space-x-20
          w-full
        "
      >
        <!-- Левая часть с пунктами меню -->
        <div class="flex flex-col md:flex-row w-full md:w-3/4 lg:w-2/3">
          <div
            class="
              flex flex-col
              space-y-10
              md:space-y-0 md:flex-row md:space-x-14
              w-full
              pt-6
              md:pt-0
            "
          >
            <!-- Эпохи -->
            <div class="w-full md:w-1/2 lg:w-1/3">
              <div
                class="
                  grid grid-cols-1
                  place-items-center
                  md:place-items-start
                  gap-y-5
                "
              >
                <p class="f-col-title font-semibold text-sm uppercase">Эпохи</p>
                <div
                  class="
                    grid grid-cols-1
                    place-items-center
                    md:place-items-start
                    gap-y-2
                  "
                >
                  <ng-container
                    *ngFor="let epoch of epochs; let i = index"
                  >
                    <a
                      (click)="navigationService.scrollToTop()"
                      routerLink="/epoch"
                      [queryParams]="{order: epoch.order}"
                      class="f-link text-sm text-center md:text-left"
                      >{{ epoch.content.menu_name }}</a
                    >
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- О полку -->
            <div class="hidden lg:block w-1/3">
              <div class="grid grid-cols-1 gap-y-5">
                <p class="f-col-title font-semibold text-sm uppercase">
                  О полку
                </p>
                <div class="grid grid-cols-1 gap-y-2">
                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/foundation"
                    class="f-link text-sm"
                    >Рождение полка</a
                  >

                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/military-glory"
                    class="f-link text-sm"
                    >Боевая слава</a
                  >

                  <!-- <a
                    (click)="navigationService.scrollToTop()"
                    [routerLink]="externalRoutes.end_of_activity"
                    class="f-link text-sm"
                    >Окончание деятельности</a
                  > -->
                </div>
              </div>
            </div>

            <!-- Другое -->
            <div class="hidden lg:block w-1/3">
              <div class="grid grid-cols-1 gap-y-5">
                <div class="grid grid-cols-1 gap-y-4">
                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/heritage-archive"
                    class="f-link text-sm uppercase"
                    >Архив наследия</a
                  >

                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/about"
                    class="f-link text-sm uppercase"
                    >О книге</a
                  >

                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/contact"
                    class="f-link text-sm uppercase"
                    >Контакты</a
                  >
                </div>
              </div>
            </div>

            <!-- О полку + Другое для планшетов :md -->
            <div class="flex lg:hidden flex-col space-y-8 w-full md:w-1/2">
              <!-- О полку -->
              <div
                class="
                  grid grid-cols-1
                  place-items-center
                  md:place-items-start
                  gap-y-5
                "
              >
                <p
                  class="
                    open-sans
                    text-white
                    opacity-60
                    font-semibold
                    text-sm
                    uppercase
                  "
                >
                  О полку
                </p>
                <div
                  class="
                    grid grid-cols-1
                    place-items-center
                    md:place-items-start
                    gap-y-2
                  "
                >
                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/foundation"
                    class="f-link text-sm"
                    >Рождение полка</a
                  >

                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/military-glory"
                    class="f-link text-sm"
                    >Боевая слава</a
                  >

                </div>
              </div>

              <!-- Другое -->
              <div class="grid grid-cols-1 gap-y-5">
                <div
                  class="
                    grid grid-cols-1
                    place-items-center
                    md:place-items-start
                    gap-y-4
                  "
                >
                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="/heritage-archive"
                    class="f-link text-sm uppercase"
                    >Архив наследия</a
                  >

                  <a
                    routerLink="/about"
                    (click)="navigationService.scrollToTop()"
                    class="f-link text-sm uppercase"
                    >О книге</a
                  >

                  <a
                    (click)="navigationService.scrollToTop()"
                    routerLink="contact"
                    class="f-link text-sm uppercase"
                    >Контакты</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Правая часть с кнопкой обратной связи -->
        <div
          class="
            flex flex-col
            space-y-8
            w-full
            md:w-1/4
            lg:w-1/3
            pb-6
            md:pb-0
            border-b border-white border-opacity-20
            md:border-0
          "
        >
          <div class="flex flex-col space-y-2.5 w-full">
            <!-- Автор -->
            <div
              class="
                flex flex-col
                space-y-1
                xl:space-y-0 xl:flex-row xl:items-center xl:space-x-1.5
                w-full
              "
            >
              <p class="f-link text-sm text-center md:text-left">Автор:</p>
              <p
                class="text-gray-300 text-sm text-center md:text-left uppercase"
              >
                Исаков Александр Владимирович
              </p>
            </div>

            <!-- Иллюстрации -->
            

            <!-- Арт-директор: -->
            

            <!-- Разработка: -->

          </div>
          <div
            class="
              flex flex-col
              items-center
              md:items-start
              space-y-4
              w-full
              md:w-auto
            "
          >
            <p
              class="
                open-sans
                text-white text-center
                md:text-left
                opacity-60
                text-xs
                w-full
                lg:w-1/3
              "
            >
              Есть жалоба или предложение?
            </p>
            <div class="hidden md:block">
              <btn-fill-elastic
                (click)="navigationService.navigateTo('/contact')"
                >Напишите нам</btn-fill-elastic
              >
            </div>

            <div class="w-full md:hidden">
              <btn-fill-elastic
                [full_width]="true"
                (click)="navigationService.navigateTo('/contact')"
                >Напишите нам</btn-fill-elastic
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Нижняя часть футтера -->
      <div
        class="
          flex flex-col-reverse
          space-y-reverse space-y-10
          lg:space-y-0 lg:flex-row lg:space-x-10
        "
      >
        <!-- Копирайт -->
        <div class="flex flex-col space-y-3 w-full md:w-9/12 lg:w-1/2">
          <div class="flex flex-row space-x-3 items-center">
            <img class="h-8" src="../../../../../assets/img/bel-10.png" />
            <img class="h-8" src="../../../../../assets/img/logo_g.svg" />
            <p class="open-sans text-white font-semibold uppercase text-xs">
              Военно-исторический интернет-проект
            </p>
          </div>
          <p class="open-sans text-white opacity-60 text-xs">
            Любая информация, представленная на данном сайте, носит
            исключительно информационный характер и ни при каких условиях не
            является публичной офертой, определяемой положениями статьи 437 ГК
            РФ.
          </p>
          <p class="open-sans text-white opacity-60 text-xs">© 2021 - {{currentYear}}</p>
        </div>

        <div
          class="
            flex flex-col
            md:flex-row
            items-center
            space-y-8
            md:space-y-0 md:space-x-6
            lg:justify-end
            w-full
            lg:w-1/2
          "
        >
          <img
            class="h-20 lg:h-24"
            src="../../../../../assets/img/d1.png"
            alt=""
          />
          <img
            class="h-20 lg:h-24"
            src="../../../../../assets/img/d2.png"
            alt=""
          />
          <img
            class="h-20 lg:h-24"
            src="../../../../../assets/img/d3.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </app-page-container>
</footer>
