import { EMapRoutesActions, MapRoutesActionsUnion, IRoutes } from "../actions/map-routes.actions";

interface IMapRoutesState {
  isLoading: boolean,
  loadIsRequired: boolean,
  routes: IRoutes[],
  progress: number,
  error: Error | null,
}

const initialState: IMapRoutesState = {
  isLoading: false,
  loadIsRequired: true,
  routes: [],
  progress: 0,
  error: null
}

function mapRoutesReducer(state = initialState, action: MapRoutesActionsUnion):IMapRoutesState {
  switch (action.type) {
    case EMapRoutesActions.LoadMapRoutes:
      return {
        ...state,
        loadIsRequired: false,
        isLoading: true,
      }
    case EMapRoutesActions.LoadMapRoutesSuccess:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: false,
        routes: action.payload.routes,
      }
    case EMapRoutesActions.LoadMapRoutesError:
      return {
        ...state,
        isLoading: false,
        loadIsRequired: true,
        error: action.payload.error,
      }
    case EMapRoutesActions.LoadMapRoutesProgress:
      return {
        ...state,
        isLoading: true,
        progress: action.payload.progress
      }
    case EMapRoutesActions.LoadMapRoutesAsync:
    default:
      return state
  }
}

export { IMapRoutesState, mapRoutesReducer }
