<div class="flex flex-row items-center w-full my-10 px-16 lg:px-20">
  <div class="w-1/2">
    <ng-container *ngIf="prevLink !== ''">
      <a
        (click)="navigationService.navigateTo(prevLink)"
        class="btn-prev flex flex-row items-center space-x-2 select-none"
      >
        <img
          id="prev"
          src="../../../../../assets/icons/west.svg"
          class="arrow-move-left"
        />
        <p
          class="open-sans text-brown font-semibold text-sm 2xl:text-base text-center hover:opacity-60 transition duration-300 ease-in-out cursor-pointer"
        >
          {{ prevTitle }}
        </p>
      </a>
    </ng-container>
  </div>
  <div class="flex felx-row items-center justify-end w-1/2">
    <ng-container *ngIf="nextLink !== ''">
      <a
        (click)="navigationService.navigateTo(nextLink)"
        class="btn-next flex flex-row items-center space-x-2 select-none"
      >
        <p
          class="open-sans text-brown font-semibold text-sm 2xl:text-base text-center hover:opacity-60 transition duration-300 ease-in-out cursor-pointer"
        >
          {{ nextTitle }}
        </p>
        <img
          id="next"
          src="../../../../../assets/icons/east.svg"
          class="arrow-move-right"
        />
      </a>
    </ng-container>
  </div>
</div>
