import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/router-navigations/navigation.service';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
})
export class PersonsComponent implements OnInit {
  collapsStatus = true;

  constructor(
    public contentService: ContentService,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
      const elems = document.querySelectorAll('.collapsible');

      M.Collapsible.init(elems);
  }
}
