<div
  class="
    relative
    border-r border-gray-300
    md:fixed
    xl:sticky
    top-0
    left-0
    xl:h-screen
    w-full
    bg-light
    z-10
    xl:z-0
  "
>
  <!-- Аудиогид -->
  <div class="absolute bottom-0 z-10 mb-5 ml-6 xl:hidden">
    <!-- <btn-fill-elastic>
      <div class="flex flex-row space-x-3 h-full items-center">
        <img
          class="select-none"
          src="../../../../../assets/icons/headset.svg"
        />
        <span>Аудиогид</span>
      </div>
    </btn-fill-elastic> -->
    <btn-fill-elastic (click)="contentService.toggleEpochContent()"
      >Закрыть карту</btn-fill-elastic
    >
  </div>

  <!-- Гугл карты / историческая карта -->
  <div class="absolute bottom-0 right-0 flex flex-row space-x-2 z-10 mb-3 mr-3">
    <button class="transition duration-300 ease-in-out" id="map-toggle-btn" onclick="imgMapAdd()">
      <btn-fill-round>
        <img
          class="select-none h-4"
          src="../../../../../assets/icons/map.svg"
        />
      </btn-fill-round>
    </button>
    <button class="transition duration-300 ease-in-out text-white" id="map-toggle-routes" onclick="toggleAnotherRoutes(event)">
      <btn-fill-round>
        <!-- Показать все маршруты -->
        <img
          class="select-none h-4"
          src="../../../../../assets/icons/map-marker.svg"
        />
      </btn-fill-round>
    </button>
  </div>

  <div
    id="box"
    class="overflow-hidden w-full h-screen z-10"
    [ngClass]="{
      hidden: !paperMapStatus
    }"
  >
    <img
      id="src"
      class="relative cursor-move"
      src="../../../../../assets/img/map.png"
    />
  </div>

  <!-- Карта -->
  <div id="map" class="fixed w-full h-screen z-0"></div>
</div>
