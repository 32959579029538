import { trigger, transition, animate, style } from '@angular/animations';

export const routeAnimation = trigger('routeChangeAnimation', [
  transition('*<=>*', [
    // css styles at start of transition
    style({ opacity: 0 }),

    // animation and styles at end of transition
    animate('250ms', style({ opacity: 1 })),
  ]),
]);
