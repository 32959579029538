import { createSelector } from '@ngrx/store';

import { IState } from '../index';

import { IEpochsState } from '../reducers/epoch.reducers';

import { IEpoch } from '../actions/epoch.actions';

const selectedEpochs = (state: IState) => state.epochs;

function selectEpoch(epochs: IEpoch[], order: number): IEpoch | null {
  if (epochs.length === 0) {
    return null
  }
  const selected = epochs.find((epoch) => epoch.order === order)
  return selected || null
}


export const selectAllEpochs = createSelector(
  selectedEpochs,
  (state: IEpochsState) => state.epochs
);

export const selectEpochByOrder = createSelector(
  selectedEpochs,
  (state: IEpochsState, order: number) => {
    return selectEpoch(state.epochs, order)
  }
);

export const progressSelector = createSelector (
  selectedEpochs,
  (state: IEpochsState) => ({ progress: state.progress, chunk: state.chunk })
);

export const isLoadingEpochs = createSelector(selectedEpochs, (state: IEpochsState) => state.isLoading);
